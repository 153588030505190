import CubeAnimation from './CubeAnimation/CubeAnimation';
import './Expertises.scss';
import IntroPrestation from './IntroPrestation/IntroPrestation';
import agenceweb from '../../assets/images/prestations/agence-web.jpg';
import ServiceExpertiseIcone from './ServiceExpertiseIcone/ServiceExpertiseIcone';
import support from '../../assets/images/prestations/support-et-maintenance.jpg';

const Expertises = () => {
  return (
    <div className="expertises">
      <IntroPrestation />
      <section>
        <div
          className="img-into-intro-two"
          style={{
            backgroundImage: `url(${agenceweb})`,
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
          }}
        />
        <div className="container-texte-intro-three">
          <div className="slide-text-intro-three-left">
            <h3 className="small-title-intro">Notre valeur ajoutée</h3>
            <p>
              Mais notre expertise ne s&apos;arrête pas là. Nous sommes
              également spécialisés dans l&apos;interfaçage ERP et les solutions
              logistiques, apportant une valeur ajoutée significative à votre
              entreprise. Que ce soit pour intégrer des processus
              d&apos;affaires complexes ou optimiser votre chaîne logistique,
              nous avons l&apos;expérience et les compétences nécessaires pour
              relever ces défis.
            </p>
          </div>
          <div className="slide-text-intro-three-right">
            <h3 className="small-title-intro">
              Une adapatabilité à votre projet
            </h3>
            <p>
              Nous travaillons avec une gamme variée de CMS, vous offrant la
              flexibilité et la personnalisation dont vous avez besoin pour
              votre projet web. De plus, notre équipe est experte en
              développement sur mesure, prête à créer des solutions uniques, des
              modules personalisés qui correspondent parfaitement à vos
              spécifications et à votre projet.
            </p>
          </div>
        </div>
      </section>
      <section className="expertise-support-maintenance">
        <div className="expertise-support-maintenance-img">
          <img src={support} alt="" />
        </div>
        <div className="expertise-support-maintenance-texte">
          <h2>Support et Maintenance de Projets Existants</h2>
          <p>
            Comprendre que chaque projet web est un organisme vivant nécessite
            une attention continue, c&apos;est pourquoi notre offre ne se limite
            pas à la création. Nous sommes également spécialisés dans le support
            et la maintenance de vos projets web existants. Que votre site ait
            été développé par nos soins ou par d&apos;autres, nous nous
            engageons à assurer son bon fonctionnement, sa sécurité et son
            évolution au fil du temps.
          </p>
          <p>
            Chez DEVOJI, nous traitons chaque projet, ancien ou nouveau, avec le
            même niveau de soin et de professionnalisme. Notre équipe technique
            procède à des audits approfondis pour identifier les éventuels
            besoins d&apos;amélioration, assure la mise à jour régulière de vos
            systèmes pour garantir la sécurité et l&apos;efficacité, et propose
            des solutions évolutives pour que votre plateforme reste au top de
            la performance et des tendances actuelles.
          </p>
        </div>
      </section>

      <CubeAnimation />
      <ServiceExpertiseIcone />
    </div>
  );
};

export default Expertises;
