import { combineReducers } from 'redux';
import cookieConsentReducer from '../cookieConsentSlice';

const rootReducer = combineReducers({
  cookieConsent: cookieConsentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
