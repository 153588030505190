import { createReducer } from '@reduxjs/toolkit';
import {
  actionToggleIsOpen,
  actionToggleIsPopupLoginOpen,
} from '../actionCreatore/actionCreators';

const initialState = {
  isOpen: false,
  isPopupLoginOpen: false, // Piloter l'affichage du menu
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actionToggleIsOpen, (state) => {
      state.isOpen = !state.isOpen;
    })
    .addCase(actionToggleIsPopupLoginOpen, (state) => {
      state.isPopupLoginOpen = !state.isPopupLoginOpen;
    });
});

export default reducer;
