/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './SlideMainServices.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import consultant from '../../../../assets/images/slides/consultant_ecommerce.jpg';
import developpement from '../../../../assets/images/slides/developpement back php symfony.jpg';
import ecommerce from '../../../../assets/images/slides/e-commerce.jpg';
import interfaçage from '../../../../assets/images/slides/interfaçage.jpg';
import vitrine from '../../../../assets/images/slides/site_vitrine.jpg';

function CustomNextArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    <div className="slick-next custom-arrow" onClick={onClick}>
      {/* Ici, vous pouvez mettre une icône ou tout autre élément pour votre bouton */}
    </div>
  );
}

function CustomPrevArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="slick-prev custom-arrow" onClick={onClick}>
      {/* Ici, vous pouvez mettre une icône ou tout autre élément pour votre bouton */}
    </div>
  );
}

const SlideMainServices = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true, // Lancement automatique
    autoplaySpeed: 2500, // Temps en ms avant de passer à la slide suivante
    nextArrow: <CustomNextArrow onClick={undefined} />,
    prevArrow: <CustomPrevArrow onClick={undefined} />,
  };

  return (
    <div className="main-slide-services-container">
      <div className="fullscreen-slider">
        <Slider {...settings}>
          <div className="slide">
            <div className="prefix-slide-title">CMS</div>
            <div className="title-slide">
              Prestashop, Magento, Shopify, WooCommerce
            </div>
            <img src={ecommerce} alt="ecommerce" />
          </div>
          <div className="slide">
            <div className="prefix-slide-title">Developpement Web</div>
            <div className="title-slide">Back, PHP : Symfony</div>
            <img src={developpement} alt="developpement web" />
          </div>
          <div className="slide">
            <div className="prefix-slide-title">Site Vitrine</div>
            <div className="title-slide">Wordpress, Sur-mesure</div>
            <img src={vitrine} alt="site vitrine" />
          </div>
          <div className="slide">
            <div className="prefix-slide-title">Interfaçage logistics ERP </div>
            <div className="title-slide">
              Cegid Y2, Dispeo, One Stock, Proximis, Api, Business Central.
            </div>
            <img src={interfaçage} alt="interfaçage api" />
          </div>
          <div className="slide">
            <div className="prefix-slide-title">AGENCE DE CONSEIL</div>
            <div className="title-slide">Consulting technique e-commerce</div>
            <img src={consultant} alt="consultant ecomemrce" />
          </div>
        </Slider>
      </div>
      <div className="right-slide-hp">
        <h2 className="h2-right-slide-hp">
          POUR RÉUSSIR VOTRE PROJET WEB, DEVOJI VOUS ACCOMPAGNE
        </h2>
        <div className="linear-right-slide-hp">
          {' '}
          <Stack sx={{ width: '60%', color: 'grey.500' }} spacing={2}>
            <LinearProgress />
          </Stack>
        </div>
        <p className="p-right-slide-hp">
          Notre agence Web accompagne nos clients dans la conception de site
          internet multi-device, dans des développements sur mesure, de
          l’interfaçage logistics / logiciels et le déploiement de stratégies
          e-marketing en leur garantissant qualité, innovation et performance.
        </p>
      </div>
    </div>
  );
};

export default SlideMainServices;
