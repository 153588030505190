import './ConsultingWeb.scss';
import consultant from '../../assets/images/consultant/consultant-web.jpg';
import consultantweb from '../../assets/images/consultant/consultant-transformation-digitale.jpg';
import consultantinfo from '../../assets/images/consultant/consulting-web.jpg';
import WebStrategy from './WebStrategy/WebStrategy';

const ConsultingWeb = () => {
  return (
    <div>
      <div className="cw-container">
        <section className="cw-section-1 cw-flex">
          <div className="cw-image-wrapper">
            <img src={consultant} alt="cms-ecommerce" className="cw-image" />
          </div>

          <div className="cw-text">
            <h1 className="cw-h1">Consulting Digital</h1>

            <h2>Exploitez notre riche expérience à votre avantage</h2>
            <p>
              Sollicitez notre expertise pour perfectionner l'aspect technique
              de votre site et enrichir l'expérience client.
              <br />
              Évaluer la maturité numérique - Définir les priorités de votre
              stratégie numérique avant d'entamer votre transformation
            </p>
            <div className="loader">
              <div className="wrapper">
                <div className="catContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 733 673"
                    className="catbody"
                  >
                    <path
                      fill="#f0f8ff"
                      d="M111.002 139.5C270.502 -24.5001 471.503 2.4997 621.002 139.5C770.501 276.5 768.504 627.5 621.002 649.5C473.5 671.5 246 687.5 111.002 649.5C-23.9964 611.5 -48.4982 303.5 111.002 139.5Z"
                    ></path>
                    <path
                      fill="#f0f8ff"
                      d="M184 9L270.603 159H97.3975L184 9Z"
                    ></path>
                    <path
                      fill="#f0f8ff"
                      d="M541 0L627.603 150H454.397L541 0Z"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 158 564"
                    className="tail"
                  >
                    <path
                      fill="#1d2735"
                      d="M5.97602 76.066C-11.1099 41.6747 12.9018 0 51.3036 0V0C71.5336 0 89.8636 12.2558 97.2565 31.0866C173.697 225.792 180.478 345.852 97.0691 536.666C89.7636 553.378 73.0672 564 54.8273 564V564C16.9427 564 -5.4224 521.149 13.0712 488.085C90.2225 350.15 87.9612 241.089 5.97602 76.066Z"
                    ></path>
                  </svg>
                  <div className="text">
                    <span className="bigzzz">Z</span>
                    <span className="zzz">Z</span>
                  </div>
                </div>
                <div className="wallContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 500 126"
                    className="wall"
                  >
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="3"
                      x2="450"
                      y1="3"
                      x1="50"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="85"
                      x2="400"
                      y1="85"
                      x1="100"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="122"
                      x2="375"
                      y1="122"
                      x1="125"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="43"
                      x2="500"
                      y1="43"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="1.99391"
                      x2="115.5"
                      y1="43.0061"
                      x1="115.5"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="2.00002"
                      x2="189"
                      y1="43.0122"
                      x1="189"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="2.00612"
                      x2="262.5"
                      y1="43.0183"
                      x1="262.5"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="2.01222"
                      x2="336"
                      y1="43.0244"
                      x1="336"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="2.01833"
                      x2="409.5"
                      y1="43.0305"
                      x1="409.5"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="43"
                      x2="153"
                      y1="84.0122"
                      x1="153"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="43"
                      x2="228"
                      y1="84.0122"
                      x1="228"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="43"
                      x2="303"
                      y1="84.0122"
                      x1="303"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="43"
                      x2="378"
                      y1="84.0122"
                      x1="378"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="84"
                      x2="192"
                      y1="125.012"
                      x1="192"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="84"
                      x2="267"
                      y1="125.012"
                      x1="267"
                    ></line>
                    <line
                      stroke-width="6"
                      stroke="#7C7C7C"
                      y2="84"
                      x2="342"
                      y1="125.012"
                      x1="342"
                    ></line>
                  </svg>
                </div>
                <p>
                  Dans le monde du web, attendre, c'est déjà reculer. N'attendez
                  pas de perdre du terrain <br />
                  Rénovez, Innovez, et prenez les devants dès aujourd'hui !
                </p>
              </div>
            </div>
          </div>
          <div className="cw-sidebar">
            <div className="cw-sidebar-contenu">
              <h2>Une Experience à la tête d'un e-commerce</h2>
              <p>
                Nous avons fondé Devoji après avoir accumulé une expérience
                solide de huit ans à la direction d'un commerce électronique.
                Gérant un catalogue de 5000 références et étendant notre
                présence sur 18 marketplaces, nous avons maîtrisé tous les
                aspects de l'activité, depuis l'importation et l'interfaçage
                logistique jusqu'au SEO et SEA, avant de conclure par la vente
                de notre société.
              </p>
            </div>
          </div>
        </section>

        <section className="cw-section custom-flex-section-2">
          <div className="cw-content-wrapper-section-2">
            <div className="cw-text-content-section-2">
              <h2>
                Pourquoi faire appel à un consultant web pour votre site ?
              </h2>
              <p>
                En choisissant notre accompagnement expert, vous ouvrez la porte
                à une transformation digitale réussie et durable. Voici pourquoi
                nous sommes votre meilleur atout :
              </p>
              <ul>
                <li>
                  <h3>Expertise Technique Approfondie :</h3>
                  <p>
                    {' '}
                    Nos consultants web possèdent une connaissance pointue des
                    dernières technologies et des meilleures pratiques en
                    matière de développement web. Cette expertise nous permet
                    d'identifier rapidement les points d'amélioration technique
                    de votre site pour une performance optimale.
                  </p>
                </li>
                <li>
                  <h3>Amélioration de l'Expérience Utilisateur :</h3>{' '}
                  <p>
                    {' '}
                    Nous plaçons l'utilisateur au cœur de notre démarche. En
                    analysant le parcours client sur votre site, nous apportons
                    des modifications ciblées qui améliorent significativement
                    l'expérience utilisateur, augmentant ainsi la satisfaction
                    de vos visiteurs et vos taux de conversion.
                  </p>
                </li>
                <li>
                  <h3>Stratégies de Contenu Personnalisées :</h3>{' '}
                  <p>
                    {' '}
                    Le contenu est roi dans le monde digital. Nous vous aidons à
                    élaborer une stratégie de contenu efficace et adaptée à
                    votre audience, pour engager vos visiteurs et améliorer
                    votre référencement naturel.
                  </p>
                </li>
                <li>
                  <h3>Conseils en Stratégie Digitale :</h3>
                  <p>
                    {' '}
                    Au-delà des aspects techniques, nous vous accompagnons dans
                    la définition de votre stratégie digitale globale. De
                    l'optimisation SEO à la présence sur les réseaux sociaux,
                    nous vous guidons vers les meilleures décisions pour
                    atteindre vos objectifs commerciaux.
                  </p>
                </li>
                <li>
                  <h3>Veille Technologique et Innovations :</h3>
                  <p>
                    {' '}
                    Le monde du web évoluant constamment, nous restons à l'affût
                    des dernières innovations pour vous proposer des solutions
                    avant-gardistes. Faites de votre site un leader de votre
                    secteur grâce à notre veille technologique.
                  </p>
                </li>
              </ul>
              <p>
                Investir dans un consultant web, c'est choisir de donner à votre
                site internet les moyens de surpasser vos concurrents.{' '}
                <strong>Contactez-nous dès aujourd'hui</strong> pour découvrir
                comment nous pouvons transformer votre présence en ligne et
                propulser votre entreprise vers de nouveaux sommets.
              </p>
            </div>
            <div className="picture-section-wrapper-section-2">
              <div className="picture-section-2">
                <img
                  src={consultantinfo}
                  alt="Consulting Service"
                  className="cw-image-section-2"
                />
              </div>
            </div>
          </div>
        </section>
        <WebStrategy />
      </div>
    </div>
  );
};

export default ConsultingWeb;
