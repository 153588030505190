import React from 'react';
import './LegalNotice.scss';

const LegalNotice = () => {
  return (
    <div className="legal-notice">
      <h1>Mentions légales</h1>
      <div>
        <h2>EDITEUR :</h2>
        <p>
          DEVOJI <br />
          SAS au capital de 20 000,00 €<br />
          49 Rue de Ponthieur
          <br />
          75008 PARIS
          <br />
          Tel : 07 82 90 88 53
        </p>
      </div>
      <div>
        <h2>Directeur de la publication :</h2>
        <p>M. Rolland Michaël – Directeur Général </p>
      </div>
      <div>
        <h2>Hébergeur</h2>
        <p>
          OVH
          <br />
          SAS au capital de 10 000 000€
          <br />
          2 rue Kellermann
          <br />
          59100 Roubaix
          <br />
        </p>
      </div>
      <div>
        <h2>CNIL</h2>
        <p>
          Délégué à la protection des données (DPO) : Michaël Rolland
          <br />
          Cette désignation porte la référence DPO-84151
        </p>
      </div>
      <div className="trait_dessus">
        <hr />
      </div>
      <h3>Propriété intellectuelle :</h3>
      <p>
        Nous vous informons que toute reproduction ou représentation totale ou
        partielle,par quelque procédé que ce soit,de ce site, des logos, des
        images pouvant y figurer est interdite sans l’autorisation expresse de
        l’agence DEVOJI et constituerait une contrefaçon sanctionnée par les
        articles L 335-2 et suivants du Code de la propriété intellectuelle.
      </p>
      <h3>Utilisation d’un Cookies</h3>
      <p>
        La navigation sur le site devoji.com (ci-après désigné par le « Site »)
        est susceptible de provoquer l’installation de cookies sur votre
        terminal. Un terminal s’entend de tout ordinateur, smartphone ou encore
        tablette, connectés à internet.
      </p>

      <h2>POURQUOI UTILISER LES COOKIES :</h2>
      <p>
        Un cookie est un fichier de petite taille, qui ne permet pas
        l’identification personnelle de l’utilisateur, mais qui enregistre
        toutefois des informations relatives à la navigation d’un terminal sur
        un site internet.
        <br /> Ces cookies améliorent l’accès à notre site internet et
        identifient les visiteurs réguliers. En outre, nos cookies améliorent
        l’expérience utilisateur grâce au suivi et au ciblage de ses intérêts.
        <br />
        Les cookies utilisés sur le Site sont déposés par la Société ou par des
        tiers.
        <br /> La Société dépose sur le Site des cookies strictement
        indispensables à la navigation sur le Site, des cookies ayant pour
        finalité exclusive de permettre ou de faciliter la communication par
        voie électronique et des cookies de mesure d’audience dont la finalité
        est limitée à la mesure d’audience du contenu visualisé afin de
        permettre une évaluation des contenus publiés et de l’ergonomie du Site.
        <br />
        Les cookies Google Analytics et Hotjar ayant pour finalité la mesure
        d’audience sont déposés par des tiers.
        <br /> La Société reçoit et enregistre automatiquement des informations
        à partir de votre terminal et de votre navigateur, y compris votre
        adresse IP, vos logiciels et votre matériel, et la page que vous
        demandez. La collecte de l’adresse IP est indispensable pour vous
        permettre de communiquer sur Internet, mais ne fournit pas une
        information plus précise que la ville, et l’adresse IP est anonymisée
        une fois la géolocalisation faite. <br />
        Les cookies utilisés en sus des cookies strictement nécessaires à la
        navigation sur le Site sont utilisés pour les finalités suivantes :{' '}
        <br />– Mesure d’audience : ces cookies permettent notamment d’établir
        des statistiques de fréquentation ;<br /> – Boutons sociaux : ces
        cookies permettent de partager le contenu du Site sur les réseaux
        sociaux ;
      </p>
      <h3>Vos droits:</h3>
      <p>
        Les cookies strictement nécessaires à la fourniture d’un service sur le
        Site expressément demandé par vous ne requièrent pas votre consentement.
        <br />
        En revanche, les cookies qui ne sont pas strictement nécessaires à la
        navigation sur le Site requièrent votre consentement. Tant que vous
        n’avez pas donné votre consentement, ces cookies ne peuvent être déposés
        ou lus sur votre terminal. Vous en êtes informé par l’apparition d’un
        bandeau.
        <br /> Vous pouvez à tout moment retirer votre consentement au dépôt ou
        à la lecture de certains cookies sur votre terminal. La durée de
        validité de ce consentement est de treize (13) mois. A l’issue de ce
        délai, votre consentement sera à nouveau recueilli.
        <br /> Les cookies sont conservés pendant une durée maximale de treize
        (13) mois sur votre terminal. Au-delà de ce délai, les cookies sont
        définitivement supprimés desdits terminaux. Cette durée n’est en aucun
        cas prorogée en cas de nouvelle visite sur le Site.
        <br /> Les données collectées via les cookies ne sont recoupées avec
        aucun autre traitement de données. Vous disposez de la faculté de vous
        opposer aux cookies par l’intermédiaire d’un mécanisme d’opposition en
        adressant une demande en ce sens à l’adresse suivante :
        contact@badaboum.paris <br />
        En cas d’exercice d’un tel droit, aucune donnée vous concernant n’est
        collectée. Vous êtes informé que le refus d’installation d’un cookie
        peut entraîner l’impossibilité d’accéder à certains services du Site.
      </p>
      <h3>PARAMÉTRAGE :</h3>
      <p>
        Afin de refuser l’installation de cookies non indispensables à la
        navigation sur le Site, vous pouvez configurer le paramétrage de votre
        navigateur internet de la manière suivante :<br /> – sous Firefox :
        onglet « Firefox/Préférences ». Cliquez sur « Vie privée » et choisissez
        « jamais » dans « Acceptez les cookies tiers ». Vous pouvez aussi
        choisir de ne conserver les cookies que jusqu’à la fermeture de Firefox
        ;<br /> – sous Google Chrome : onglet « Plus ». Cliquez sur « Paramètres
        » puis « Paramètres avancés ». dans la section « Confidentialité et
        sécurité », cliquez sur « Paramètres du contenu », puis cliquez sur «
        Cookies » et désactivez « Autoriser les sites à enregistrer/lire les
        données des cookies ». vous pouvez également choisir de l’activer ;{' '}
        <br />– sous Safari : onglet « safari/Préférences ». Cliquez sur «
        Confidentialité » et choisissez « Toujours bloquer » dans « Cookies et
        données de sites web ». A la fin de la navigation vous pouvez aussi
        cliquer sur « Supprimer toutes les données de sites web ». Vous pouvez
        également choisir d’activer la fonction « ne pas me suivre » ; <br />–
        sous Edge : onglet « Options internet », puis « confidentialité », au
        sein duquel vous pouvez paramétrer la zone internet et limiter l’accès
        des cookies en choisissant l’option confidentialité « Haute » qui bloque
        les cookies avec des stratégie de confidentialité insuffisante ainsi que
        les cookies qui enregistrent des informations sans le consentement des
        utilisateurs ;<br /> – sous Opera : onglet « Préférences ». Cliquez sur
        « Avancé » puis cliquez sur « Cookies ». Vous pouvez choisir soit
        d’accepter tous les cookies, soit de n’accepter que ceux du site visité,
        soit de ne jamais accepter les cookies.
        <br /> Attention toutefois, la prise en compte de votre consentement
        repose sur un cookie. Ainsi, si vous supprimez l’ensemble des cookies
        enregistrés dans votre terminal par l’intermédiaire de votre navigateur
        internet, la Société sera dans l’impossibilité de savoir que vous avez
        choisi cette option. Pour les cookies Google analytics, vous pouvez
        accéder à davantage d’informations, notamment comment les désactiver en
        suivant le lien suivant :
        https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usagehttps://tools.google.com/dlpage/gaoptout?hl=fr.
        <br />
        Pour les boutons sociaux, les politiques de protection des données des
        fournisseurs de réseaux sociaux doivent vous permettre d’exercer vos
        choix directement auprès de ces réseaux sociaux dans les paramètres de
        vos comptes d’utilisation. <br />
        Attention : si vous refusez systématiquement l’installation de tous les
        cookies sur votre terminal, y compris ceux strictement nécessaires à la
        navigation, via les options « bloquer tous les cookies », votre
        navigation sur le Site pourra être limitée, et l’accès à certains
        services pourra s’avérer impossible. Pour plus d’informations, vous
        pouvez consulter le site de la CNIL via le lien suivant :
        https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
      </p>
    </div>
  );
};

export default LegalNotice;
