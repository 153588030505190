import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import ComposantCms from '../ComposantCms/ComposantCms';
import { CmsDataType, cmsData } from '../../../Data/cmsData';
import './LogosCms.scss';
import magentoLogo from '../../../assets/icones/magento.svg';
import shopifyLogo from '../../../assets/icones/shopify.svg';
import prestashopLogo from '../../../assets/icones/prestashop.svg';
import woocommerceLogo from '../../../assets/icones/woocommerce.svg';
import syliusLogo from '../../../assets/icones/sylius.svg';

Modal.setAppElement('#root');

const LogosCms: React.FC = () => {
  const firstCmsKey = Object.keys(cmsData)[0];
  const [selectedCms, setSelectedCms] = useState<CmsDataType | null>(
    cmsData[firstCmsKey]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLiClick = useCallback(
    (cmsKey: string) => {
      const cms = cmsData[cmsKey];
      if (cms) {
        setSelectedCms(cms);
        if (!isDesktop) {
          setIsModalOpen(true);
        }
      }
    },
    [isDesktop]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLLIElement>, cmsKey: string) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleLiClick(cmsKey);
      }
    },
    [handleLiClick]
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div className="container-logos-cms">
      <div className="top-ecommerce-left">
        <ul>
          {Object.keys(cmsData).map((key) => (
            <li
              key={key}
              onClick={() => handleLiClick(key)}
              onKeyDown={(e) => handleKeyDown(e, key)}
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              aria-label={`Open ${cmsData[key].title}`}
            >
              <a href={`#${key}`}>
                <img
                  src={getLogo(key)}
                  alt={`${cmsData[key].title} logo`}
                  className="brand"
                />
                <span>{cmsData[key].title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="top-ecommerce-right">
        <div className="composant-desktop-ecommerce">
          {selectedCms && (
            <ComposantCms
              title={selectedCms.title}
              content={selectedCms.content}
              backgroundColor={selectedCms.backgroundColor}
              url={selectedCms.url}
            />
          )}
        </div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
          >
            <button
              type="button"
              className="modal-close-button"
              onClick={closeModal}
            >
              &times;
            </button>
            {selectedCms && (
              <ComposantCms
                title={selectedCms.title}
                content={selectedCms.content}
                backgroundColor={selectedCms.backgroundColor}
                url={selectedCms.url}
              />
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

function getLogo(cmsKey: string): string {
  switch (cmsKey) {
    case 'magento':
      return magentoLogo;
    case 'shopify':
      return shopifyLogo;
    case 'prestashop':
      return prestashopLogo;
    case 'woocommerce':
      return woocommerceLogo;
    case 'sylius':
      return syliusLogo;
    default:
      return '';
  }
}

export default LogosCms;
