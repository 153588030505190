export type CmsDataType = {
  title: string;
  content: string;
  backgroundColor: string;
  url: string;
};

export const cmsData: { [key: string]: CmsDataType } = {
  magento: {
    title: 'Magento',
    content:
      'Vous désirez mettre en place une boutique en ligne MAGENTO, vous devez commencer par connaitre tous les points forts de ce logiciel. Un CMS (Content Management System) est un logiciel, ou plutôt une plateforme permettant de créer un site web et de gérer son contenu.',
    backgroundColor: '#eb6006',
    url: '/magento/',
  },
  shopify: {
    title: 'Shopify',
    content:
      'DEVOJI est un partenaire officiel de Shopify et travail en étroite collaboration avec Shopify. Cela permet d’avoir accès à des ressources exclusives, des formations, des mises à jour de produits, des fonctions bêta et des événements… C’est donc une communauté d’agences et de technologies commerciales de pointe qui collaborent pour vous aider à réussir.',
    backgroundColor: '#90b944',
    url: '/boutique-shopify/',
  },
  prestashop: {
    title: 'Prestashop',
    content:
      'Ce CMS dédié aux boutiques en ligne est connu pour sa simplicité de prise en main. Prestashop même de base, comprend une panoplie de fonctionnalités des plus complètes pour la création et la gestion d’un site e-commerce. En effet, l’application est livrée avec plus de 300 fonctionnalités. Allant des fonctions de gestions de catalogue aux outils d’analyses statistiques, sans oublier les opérations marketing et le multilingue.',
    backgroundColor: '#db006c',
    url: '/prestashop-ecommerce/',
  },
  woocommerce: {
    title: 'Woocommerce',
    content:
      'WooCommerce est une solution de création et de gestion de boutique en ligne sous WordPress. Il s’agit d’une extension open source pour WordPress plutôt que d’un CMS (Content Management System) à part entière. Cette solution e-commerce développée par Jay Koster et Mike Jolley, connu un grand succès depuis son lancement en septembre 2011.',
    backgroundColor: '#7f54b3',
    url: '/woocommerce/',
  },
  sylius: {
    title: 'Sylius',
    content:
      'Sylius est une plateforme E-commerce innovante avec comme principaux avantages, sa capacité à évoluer en fonction des exigences changeantes du marché et des besoins des utilisateurs. Avec Sylius, les entreprises peuvent facilement ajuster et étendre leurs fonctionnalités, assurant ainsi une expérience utilisateur optimale et une performance commerciale accrue.',
    backgroundColor: '#1a9f83',
    url: '/sylius-symfony/',
  },
};
