import './AnimationMultiFormat.scss';

const AnimationMultiFormat = () => {
  return (
    <div className="container-animation-multiformat">
      <div className="loader-animation-multiformat" />
      <div className="loader-animation-multiformat" />
      <div className="loader-animation-multiformat" />
    </div>
  );
};

export default AnimationMultiFormat;
