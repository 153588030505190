import './ExperienceComposant.scss';

const ExperienceComposant = () => {
  return (
    <div className="experience-composant">
      <div className="left-experience-composant" />
      <div className="center-experience-composant">
        <h2>
          Développement Backend, Frontend, CMS et Interfaçage pour Solutions Web
          Avancées
        </h2>
        <h3>&Xi; Expertise en Développement Backend :</h3>
        <p>
          Notre cœur de compétence réside dans le développement backend. Nous
          utilisons PHP et des frameworks tels que Symfony pour construire des
          architectures backend robustes et évolutives. Notre maîtrise de ces
          technologies nous permet de développer des applications complexes, des
          systèmes de gestion de données et des interfaces utilisateur backend
          intuitives.
        </p>
        <ul>
          <li>
            <span>PHP & Frameworks :</span> Utilisation de PHP avec Symfony pour
            des performances optimales et une meilleure gestion du code.
          </li>
          <li>
            <span>Base de Données et Gestion des Données :</span> conception et
            optimisation des bases de données pour assurer l&rsquo;intégrité, la
            sécurité et l&rsquo;efficacité des données.
          </li>
          <li>
            <span>Sécurité et Maintenance :</span>mise en œuvre de protocoles de
            sécurité rigoureux pour protéger les données et assurer la
            maintenance continue du système.
          </li>
        </ul>
        <h3>&Xi; Interfaçage ERP et Logistique :</h3>
        <p>
          Nous excellons dans l&rsquo;interfaçage avec divers systèmes ERP et
          solutions logistiques, garantissant une intégration transparente et
          efficace.
        </p>
        <ul>
          <li>
            <span>ERP :</span>Intégration avec des systèmes comme Cegid Y2,
            Business Central, garantissant une gestion fluide des ressources et
            des processus d&rsquo;affaires.
          </li>
          <li>
            <span>Solutions Logistiques :</span>Interfaçage avec des systèmes
            comme Dispeo, One Stock, Proximis, permettant une gestion logistique
            et une chaîne d&rsquo;approvisionnement optimisées.
          </li>
          <li>
            <span>APIs et Connecteurs :</span>Développement d&rsquo;APIs
            personnalisées et de connecteurs pour assurer une communication sans
            faille entre différents systèmes.
          </li>
        </ul>
        <h3>&Xi; Développement Frontend </h3>
        <p>
          Nous utilisons des technologies comme JavaScript pour offrir des
          fonctionnalités interactives et dynamiques. Notre maîtrise de React JS
          et Redux garantit des applications web robustes et réactives.
        </p>
        <h3>&Xi; Expertise CMS Complète</h3>
        <p>
          Notre expertise s&rsquo;étend à des plateformes renommées telles que
          Magento (1 et 2), Shopify, Prestashop, WooCommerce. Cette diversité
          nous permet de fournir des solutions personnalisées, que ce soit pour
          des projets de grande envergure ou des besoins spécifiques.
        </p>
      </div>
      <div className="right-experience-composant" />
    </div>
  );
};

export default ExperienceComposant;
