import React, { useEffect, useRef } from 'react';
import './AgencyPresentation.scss';
import parisBackground from '../../../assets/images/homepage/site-internet-agence-web-paris.jpg';
import lemansBackground from '../../../assets/images/homepage/creation-site-internet-le-mans.jpg';
import paris from '../../../assets/images/homepage/agenc-web-paris.jpg';
import lemans from '../../../assets/images/homepage/agence-web-le-mans.jpg';

const AgencyPresentation = () => {
  const lineParisRef = useRef(null);
  const lineLeMansRef = useRef(null);
  const leftSectionStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(18, 29, 40), rgba(18, 29, 40, 0.7)), url(${parisBackground})`,
    backgroundSize: 'cover',
  };

  const rightSectionStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(216, 216, 216), rgba(216, 216, 216, 0.7)), url(${lemansBackground})`,
    backgroundSize: 'cover',
  };

  useEffect(() => {
    const currentLineParis = lineParisRef.current;
    const currentLineLeMans = lineLeMansRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('start-animation');
          }
        });
      },
      { threshold: 0.5 }
    );

    if (currentLineParis) {
      observer.observe(currentLineParis);
    }

    if (currentLineLeMans) {
      observer.observe(currentLineLeMans);
    }

    return () => {
      // Fonction de nettoyage
      if (currentLineParis) {
        observer.unobserve(currentLineParis);
      }

      if (currentLineLeMans) {
        observer.unobserve(currentLineLeMans);
      }
    };
  }, []); // Les références ne sont pas ajoutées en tant que dépendances

  return (
    <div>
      <h2 className="title-h2-agency-presentation">Rencontrez-nous</h2>
      <div className="agency-presentation">
        <section className="left-agence-presentation" style={leftSectionStyle}>
          <div className="animated-line-paris" ref={lineParisRef}>
            <div className="line-paris" />
            <div className="word-paris">
              <div className="title-image-container-agency">
                <h3>Paris</h3>
                <p>Ile de France</p>
                <img
                  src={paris}
                  alt="agence-web-paris"
                  className="image-pole-paris"
                />
              </div>
            </div>
          </div>
        </section>
        <section
          className="right-agence-presentation"
          style={rightSectionStyle}
        >
          <div className="animated-line-lemans" ref={lineLeMansRef}>
            <div className="line-lemans" />
            <div className="word-lemans">
              <div className="title-image-container-agency">
                <img
                  src={lemans}
                  alt="agence-web-le-mans"
                  className="image-pole-lemans"
                />
                <h3>Le Mans</h3>
                <p>Pays de la Loire</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AgencyPresentation;
