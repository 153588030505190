import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Header from '../Header/Header';
import NotFound from '../NotFound/NotFound';
import HomPage from '../HomPage/HomPage';
import Expertises from '../Prestation/Expertises';
import Agence from '../Agence/Agence';
import Ecommerce from '../Ecommerce/Ecommerce';
import Footer from '../Footer/Footer';
import Contact from '../Contact/Contact';
import PersonalData from '../PersonalData/PersonalData';

import landingPage from '../../Data/landing-page-cms';
import CmsPageDetails from '../CmsPageDetails/CmsPageDetails';
import './App.scss';
import LegalNotice from '../LegalNotice/LegalNotice';
import ConsultingWeb from '../ConsultingWeb/ConsultingWeb';
import cmsDataItem from '../../Data/details-cmsData';
import LandingPageCMS from '../LandingPageCMS/LandingPageCMS';
import CookieBanner from '../CookiesBanner/CookieBanner';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomPage />} />
        <Route path="/nos-expertises" element={<Expertises />} />
        <Route path="/agence" element={<Agence />} />
        <Route path="/offre-ecommerce" element={<Ecommerce />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donnees-personnelles" element={<PersonalData />} />
        <Route path="/mentions-legales" element={<LegalNotice />} />
        <Route path="/consulting-web" element={<ConsultingWeb />} />
        <Route
          path="/prestashop-ecommerce"
          element={
            <CmsPageDetails
              cmsData={cmsDataItem}
              pageSlug="prestashop-ecommerce"
            />
          }
        />
        <Route
          path="/magento"
          element={<CmsPageDetails cmsData={cmsDataItem} pageSlug="magento" />}
        />
        <Route
          path="/boutique-shopify"
          element={
            <CmsPageDetails cmsData={cmsDataItem} pageSlug="boutique-shopify" />
          }
        />
        <Route
          path="/woocommerce"
          element={
            <CmsPageDetails cmsData={cmsDataItem} pageSlug="woocommerce" />
          }
        />
        <Route
          path="/sylius-symfony"
          element={
            <CmsPageDetails cmsData={cmsDataItem} pageSlug="sylius-symfony" />
          }
        />
        <Route
          path="/interface-erp"
          element={
            <LandingPageCMS cmsData={landingPage} pageSlug="interface-erp" />
          }
        />
        <Route
          path="/interface-logistique"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="interface-logistique"
            />
          }
        />
        <Route
          path="/github-bitBucket"
          element={
            <LandingPageCMS cmsData={landingPage} pageSlug="github-bitBucket" />
          }
        />
        <Route
          path="/seo-referencement-naturel"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="seo-referencement-naturel"
            />
          }
        />
        <Route
          path="/marketplaces-guides-shopping"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="marketplaces-guides-shopping"
            />
          }
        />
        <Route
          path="/configuration-serveur"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="configuration-serveur"
            />
          }
        />
        <Route
          path="/developpement-web"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="developpement-web"
            />
          }
        />
        <Route
          path="/symfony"
          element={<LandingPageCMS cmsData={landingPage} pageSlug="symfony" />}
        />
        <Route
          path="/react-js"
          element={<LandingPageCMS cmsData={landingPage} pageSlug="react-js" />}
        />
        <Route
          path="/formations-webmarketing"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="formations-webmarketing"
            />
          }
        />
        <Route
          path="/site-wordpress"
          element={
            <LandingPageCMS cmsData={landingPage} pageSlug="site-wordpress" />
          }
        />
        <Route
          path="/netlinking-semantique"
          element={
            <LandingPageCMS
              cmsData={landingPage}
              pageSlug="netlinking-semantique"
            />
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <CookieBanner />
    </div>
  );
}

export default App;
