import './MainSectionServices.scss';
import SlideMainServices from './SlideMainServices/SlideMainServices';

const MainSectionServices = () => {
  return (
    <div className="slidemainservices">
      <SlideMainServices />
    </div>
  );
};

export default MainSectionServices;
