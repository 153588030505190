import './ComposantCms.scss';
import { Link } from 'react-router-dom';

interface ComposantCmsProps {
  title: string;
  content: string;
  backgroundColor: string;
  url: string;
}

const ComposantCms: React.FC<ComposantCmsProps> = ({
  title,
  content,
  backgroundColor,
  url,
}) => {
  const style = {
    backgroundColor,
  };

  return (
    <div className="composant-cms" style={style}>
      <h2>{title}</h2>
      <p>{content}</p>
      <Link to={url} style={{ textDecoration: 'none' }}>
        <button className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text">En Savoir plus</span>
          </span>
        </button>
      </Link>
    </div>
  );
};

export default ComposantCms;
