import React from 'react';
import './Footer.scss';
import Logo from '../../assets/images/logo_devoji.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-column-1">
          <img src={Logo} alt="Logo Devoji" className="footer-logo" />
          <h5>Agence Web Paris | DEVOJI</h5>
          <p>
            Devoji vous accompagne dans le déploiement et l’optimisation de
            votre visibilité sur Internet. Concepteur, développeur, hébergeur,
            marketeur, nous vous accompagnons tout au long de votre
            transformation digitale.
          </p>
          <div className="footer-address">
            <h5>Coordonées</h5>
            <address>
              49 rue de Ponthieu,75008 Paris
              <p>contact@devoji.com</p>
            </address>
          </div>
        </div>

        <div className="footer-column-2">
          <div className="footer-column-split">
            <ul>
              <li>
                <Link to="/magento">Magento</Link>
              </li>
              <li>
                <Link to="/prestashop-ecommerce">Prestashop</Link>
              </li>
              <li>
                <Link to="/boutique-shopify">Shopify</Link>
              </li>
              <li>
                <Link to="/woocommerce">Woocommerce</Link>
              </li>
              <li>
                <Link to="/site-wordpress">Wordpress</Link>
              </li>
              <li>
                <Link to="/sylius-symfony">Sylius</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/interface-erp">Interfaçage ERP</Link>
              </li>
              <li>
                <Link to="/interface-logistique">Interfaçage logistique</Link>
              </li>
              <li>
                <Link to="/github-bitBucket">Github ou BitBucket</Link>
              </li>
              <li>
                <Link to="/seo-referencement-naturel">
                  Référence Naturel SEO
                </Link>
              </li>
              <li>
                <Link to="/netlinking-semantique">Netlinking-semantique</Link>
              </li>
              <li>
                <Link to="/marketplaces-guides-shopping">
                  Marketplaces / Guides Shopping
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/configuration-serveur">
                  Administration Système Linux
                </Link>
              </li>
              <li>
                <Link to="/developpement-web">Developpeur sur mesure</Link>
              </li>
              <li>
                <Link to="/symfony">Symfony</Link>
              </li>
              <li>
                <Link to="/react-js">React JS (Redux, TypeScript, Eslint)</Link>
              </li>
              <li>
                <Link to="/formations-webmarketing">
                  Formation Webmarketing
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h5>Liens</h5>
            <div className="footer-legales">
              <p>
                <a
                  href="https://blog.devoji.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </p>
              <p>
                <Link to="/donnees-personnelles">Données personnelles</Link>
              </p>
              <p>
                <Link to="/mentions-legales">Mentions légales</Link>
              </p>
              <p>
                <Link to="/contact">Contactez-nous</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
