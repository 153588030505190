import React from 'react';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="font-404-container">
      <div className="main-404">
        <div className="content-404">
          <div className="font-404">
            <div className="loader JS_on">
              <span className="binary"></span>
              <span className="binary"></span>
              <span className="getting-there">OUPS...</span>
            </div>
            <h1>
              4<span>0</span>4
            </h1>
            <p>
              La page que vous recherchez a peut-être été supprimée, son URL a
              changé ou est temporairement indisponible.
            </p>
            <a href="/">home page</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
