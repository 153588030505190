import React from 'react';
import './PerformanceImage.scss';
import performance from '../../../assets/images/homepage/performance-site-web.jpg';
import 'animate.css';

const PerformanceImage = () => {
  return (
    <div
      className="background-image-container animate__animated animate__fadeIn"
      style={{ backgroundImage: `url(${performance})` }}
    >
      <div className="image-overlay">
        <h1 className="text-overlay animate__animated animate__bounce">
          Les tendances ne font pas les performances.
        </h1>
      </div>
    </div>
  );
};

export default PerformanceImage;
