import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './PartnerLogosSlider.scss';

import gefco from '../../../assets/images/parteniares/GEFCO.png';
import imbert from '../../../assets/images/parteniares/atelier-imbert.webp';
import eqpmt from '../../../assets/images/parteniares/eqpmt.png';
import finexo from '../../../assets/images/parteniares/finexo.png';
import hegoa from '../../../assets/images/parteniares/galeriehegoa.webp';
import sixtine from '../../../assets/images/parteniares/marie-sixtine.png';
import moismont from '../../../assets/images/parteniares/moismont.webp';
import reosecherie from '../../../assets/images/parteniares/rosecherie.png';
import sds from '../../../assets/images/parteniares/sds.png';

const DiaporamaLogosPartenaires = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 650,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="diaporama-logos-partenaires">
      <h2>Nos Références</h2>
      <p>Découvrez les entreprises qui nous font confiance au quotidien :</p>
      <Slider {...settings}>
        {[
          gefco,
          imbert,
          eqpmt,
          finexo,
          hegoa,
          sixtine,
          moismont,
          reosecherie,
          sds,
        ].map((logo, index) => (
          <div key={index} className="logo-container-hp">
            <img
              src={logo}
              alt={`Logo partenaire ${index + 1}`}
              className="logo-image-hp"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DiaporamaLogosPartenaires;
