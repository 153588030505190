import React from 'react';
import './ExpertiseSectionSquare.scss';

interface ExpertiseSectionSquareProps {
  image: string;
  title: string;
  description: string;
}

function ExpertiseSectionSquare({
  image,
  title,
  description,
}: ExpertiseSectionSquareProps) {
  return (
    <div className="container-expertise-square">
      <img src={image} alt={title} className="image-expertise-square" />
      <div className="overlay-expertise-square">
        <div className="title-expertise-square">{title}</div>
        <div className="description-expertise-square">{description}</div>
      </div>
    </div>
  );
}

export default ExpertiseSectionSquare;
