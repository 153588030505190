import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { setConsent } from '../store/cookieConsentSlice';
import { RootState } from '../store/reducer/cookieReducer';

import './CookieBanner.scss';

const CookieBanner: React.FC = () => {
  const dispatch = useDispatch();
  const consentGiven = useSelector(
    (state: RootState) => state.cookieConsent.consentGiven
  );

  const [isVisible, setIsVisible] = useState(!consentGiven);

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    if (cookieConsent === 'accepted') {
      setIsVisible(false);
    }
  }, []);

  const handleAccept = () => {
    dispatch(setConsent(true));
    setIsVisible(false);

    Cookies.set('cookieConsent', 'accepted', { expires: 30, path: '/' });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner-popup">
      <p>
        Ce site utilise des cookies. En continuant à naviguer sur ce site, vous
        acceptez notre utilisation des cookies.
      </p>
      <a href="/donnees-personnelles">En savoir plus sur les cookies</a>
      <button type="button" id="monInput" onClick={handleAccept}>
        Accepter
      </button>
    </div>
  );
};

export default CookieBanner;
