/* eslint-disable react/button-has-type */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './MobileMenu.scss';
import { actionToggleIsOpen } from '../../store/actionCreatore/actionCreators';
import logoDevoji from '../../../assets/images/logo_devoji.png';

const MobileMenu: React.FC<AppMenuProps> = ({ isOpen }) => {
  const dispatch = useDispatch();

  const handleClickCloseMenu = () => {
    dispatch(actionToggleIsOpen());
  };

  return (
    <div>
      <nav className={isOpen ? 'AppMenu AppMenu--open' : 'AppMenu'}>
        {isOpen && (
          <button className="menu-close" onClick={handleClickCloseMenu}>
            &#10005; {/* Symbole de la croix */}
          </button>
        )}

        <div className="ui vertical menu">
          <NavLink
            className="item"
            to="/nos-expertises"
            onClick={handleClickCloseMenu}
          >
            <div className="containerItem">
              <div className="header">Nos Expertises</div>
            </div>
          </NavLink>
          <NavLink
            className="item"
            to="/offre-ecommerce"
            onClick={handleClickCloseMenu}
          >
            <div className="containerItem">
              <div className="header">Offre E-commerce</div>
            </div>
          </NavLink>
          <NavLink className="item" to="/agence" onClick={handleClickCloseMenu}>
            <div className="containerItem">
              <div className="header">L&rsquo;agence</div>
            </div>
          </NavLink>
          <NavLink
            className="item"
            to="/consulting-web"
            onClick={handleClickCloseMenu}
          >
            <div className="containerItem">
              <div className="header">Consulting Web</div>
            </div>
          </NavLink>

          <NavLink
            className="item"
            to="/contact"
            onClick={handleClickCloseMenu}
          >
            <div className="containerItem">
              <div className="header">Contactez-nous</div>
            </div>
          </NavLink>
          <img src={logoDevoji} alt="Devoji Logo" className="logo" />
        </div>
      </nav>
    </div>
  );
};

type AppMenuProps = {
  isOpen: boolean;
};

export default MobileMenu;
