import React from 'react';
import './ServiceExpertiseIcone.scss';
import maintenance from '../../../assets/icones/maintenance-web.png';
import developpement from '../../../assets/icones/deveveloppemet-web.png';
import cms from '../../../assets/icones/cms-ecommerce.png';

const ServiceExpertiseIcone = () => {
  return (
    <div className="service-expertise">
      <h2>Des solutions web personnalisées pour votre entreprise</h2>
      <p>Une expertise web dédiée à vos projets sur mesure</p>
      <div className="service-container">
        <div className="service">
          <div className="icon">
            <span>
              <img
                src={cms}
                alt="cms-ecommerce"
                className="icone-service-expertise"
              />
            </span>
          </div>
          <h3>Conception de sites web professionnels</h3>
          <ul>
            <li>Solutions personnalisées pour votre présence en ligne</li>
            <li>Sites et boutiques optimisés pour mobiles et tablettes</li>
            <li>Interface d'administration intuitive pour votre site</li>
            <li>Création de sites internet rapides et fiables</li>
            <li>Plateformes web flexibles pour s'adapter à l'avenir</li>
          </ul>
        </div>
        <div className="service">
          <div className="icon">
            <span>
              {' '}
              <img
                src={developpement}
                alt="deveveloppemet-web"
                className="icone-service-expertise"
              />{' '}
            </span>
          </div>
          <h3>Riche expérience dans le développement</h3>
          <ul>
            <li>15 ans d'expertise reconnue en développement web</li>
            <li>CMS : Shopify, prestashop, Magento, Woocommerce, Wordpress</li>
            <li>Développement backend : PHP, Symfony</li>
            <li>Développement Frontend : CSS, Javascript, REACT JS</li>
            <li>Langage de programmation php, ruby, javascript, html</li>
          </ul>
        </div>
        <div className="service">
          <div className="icon">
            <span>
              {' '}
              <img
                src={maintenance}
                alt="maintenance-web"
                className="icone-service-expertise"
              />
            </span>
          </div>
          <h3>Support et maintenance pour boutiques en ligne</h3>
          <ul>
            <li>Intervention et résolution rapides des incidents</li>
            <li>Gestion proactive de votre plateforme e-commerce</li>
            <li>Améliorations continues et actualisation de votre site</li>
            <li>Mise à niveau des fonctionnalités de votre site e-commerce</li>
            <li>
              Audits précis, conseils personnalisés et mise à jour technologique
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ServiceExpertiseIcone;
