import './FondateurComposant.scss';
import image1 from '../../../assets/images/agence/equipe/agence-web-devoji.jpg';
import image2 from '../../../assets/images/agence/equipe/agence prestashop.jpg';
import image3 from '../../../assets/images/agence/equipe/agence magento.jpg';
import image4 from '../../../assets/images/agence/equipe/Vincent-DEVOJI.jpeg';
import image5 from '../../../assets/images/agence/equipe/Michaël-DEVOJI.jpeg';

const FondateurComposant = () => {
  return (
    <div className="fondateur-composant">
      <section className="left-fondateur-composant">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
        <img src={image5} alt="" />
      </section>
      <section className="right-fondateur-composant">
        <p className="summary">&#9568; Lancée en avril 2018</p>
        <h2>
          DEVOJI - L&rsquo;Expertise Digitale Forjée par l&rsquo;Expérience
        </h2>
        <p>
          Rencontrez Michaël et Vincent, deux passionnés du numérique avec plus
          d’une décennie d’expérience dans le monde du Web. Leur parcours est
          marqué par des contributions significatives dans diverses entreprises
          du secteur, culminant avec la création et la gestion réussie d’une
          boutique en ligne spécialisée dans la décoration d’intérieur.
        </p>
        <p>
          À la barre de ce site e-commerce, ils ont brillamment manœuvré à
          travers les défis du développement digital. Leur stratégie comprenait
          l’utilisation de divers outils Web : campagnes Adwords, comparateurs
          de prix, places de marché, un SEO approfondi et une présence active
          sur les réseaux sociaux. Ils n’ont jamais négligé l’aspect technique,
          assurant une mise à niveau constante du site ainsi que des solutions
          logistiques automatisées.
        </p>
        <p>
          Après le rachat de leur boutique par un acteur du marché, Michaël et
          Vincent ont choisi de mettre leur expertise au service d&rsquo;autres
          entreprises. Ainsi naît en 2018, DEVOJI, une agence dédiée à
          transformer votre vision en succès digital.{' '}
        </p>
        <p className="pick-up-line-right-fondateur-composant">
          Rejoignez-nous pour une aventure où votre potentiel digital sera
          pleinement réalisé.
        </p>
      </section>
    </div>
  );
};

export default FondateurComposant;
