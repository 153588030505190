import RefonteSiteWeb from './ RefonteSiteWeb/ RefonteSiteWeb';
import './Ecommerce.scss';
import LogosCms from './LogosCms/LogosCms';
import TopEcommerce from './TopEcommerce/TopEcommerce';

const Ecommerce = () => {
  return (
    <div className="container-ecommerce">
      <div className="container-main-ecommerce">
        <TopEcommerce />
        <LogosCms />
        <RefonteSiteWeb />
      </div>
    </div>
  );
};

export default Ecommerce;
