import { NavLink } from 'react-router-dom';
import './DesktopMenu.scss'; // Renamed the CSS file

const DesktoplMenu = () => {
  // Renamed the component for clarity
  return (
    <div className="horizontal-menu">
      <NavLink className="item" to="/nos-expertises">
        Nos Expertises
      </NavLink>
      <NavLink className="item" to="/offre-ecommerce">
        Offre E-commerce
      </NavLink>
      <NavLink className="item" to="/agence">
        L&rsquo;agence
      </NavLink>
      <NavLink className="item" to="/consulting-web">
        Consulting Web
      </NavLink>
      <NavLink className="item" to="/contact">
        Contactez-nous
      </NavLink>
    </div>
  );
};

export default DesktoplMenu;
