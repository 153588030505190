import React, { useState } from 'react';
import { Autoplay, Controller, EffectCube } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import './CubeAnimation.scss';
import vitrine from '../../../assets/images/prestations/site-vitrine.jpg';
import ecommerce from '../../../assets/images/prestations/site-ecommerce.jpg';
import developpement from '../../../assets/images/prestations/developpement-web.jpg';
import front from '../../../assets/images/prestations/dev-front-react.jpg';
import erp from '../../../assets/images/prestations/interfaçage-erp.jpg';
import consulting from '../../../assets/images/prestations/Consulting-technique-ecomerce.jpg';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/bundle';
import 'swiper/css/effect-cube';

const CubeAnimation = () => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperClass | null>(null);
  const [secondSwiper, setSecondSwiper] = useState<SwiperClass | null>(null);

  return (
    <div className="cube-animation">
      <div className="left-cube-animation">
        <Swiper
          modules={[Controller]}
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
        >
          <SwiperSlide>
            <h2>Site Vitrine</h2>
            <p>Wordpress, From Scratch</p>
          </SwiperSlide>

          <SwiperSlide>
            <h2>CMS</h2>
            <p>Prestashop, Magento, Shopify, Woocommerce</p>
          </SwiperSlide>

          <SwiperSlide>
            <h2>DÉVELOPPEMENT WEB Back</h2>
            <p>Dev Back PHP, Symfony</p>
          </SwiperSlide>

          <SwiperSlide>
            <h2>DÉVELOPPEMENT Front</h2>
            <p>CSS, React</p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>Interfaçage</h2>
            <p>
              logistics ERP : Cegid Y2, Dispeo, One Stock, Proximis, Api,
              Business Central.{' '}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h2>AGENCE DE CONSEILs</h2>
            <p>Consulting technique e-commerce</p>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="right-cube-animation">
        <Swiper
          effect="cube"
          grabCursor
          autoplay={{
            delay: 2200,
            disableOnInteraction: false,
          }}
          loop
          cubeEffect={{
            shadow: true,
            slideShadows: true,

            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          pagination
          modules={[Controller, EffectCube, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          className="mySwiper"
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          speed={2000}
        >
          <SwiperSlide>
            <img
              src={vitrine}
              alt="Développement back-end PHP Symfony"
              className="img-swipper-slide"
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={ecommerce}
              alt="Plateforme E-commerce"
              className="img-swipper-slide"
            />
          </SwiperSlide>

          <SwiperSlide>
            <img
              src={developpement}
              alt="Interfaçage Système"
              className="img-swipper-slide"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={front}
              alt="Interfaçage Système"
              className="img-swipper-slide"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={erp}
              alt="Interfaçage Système"
              className="img-swipper-slide"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={consulting}
              alt="Interfaçage Système"
              className="img-swipper-slide"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CubeAnimation;
