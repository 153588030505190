import './Contact.scss';
import contact from '../../assets/images/contact/Devis-site-internet-equipe-DEVOJI.webp';

const Contact = () => {
  return (
    <>
      <div className="container-contact">
        <div className="left-container-contact">
          <div className="left-content">
            <h2>
              L&rsquo;innovation web commence ici,
              <br /> chez DEVOJI
            </h2>
            <p>
              Vous avez une idée, un projet, une ambition ?<br /> Nous sommes à
              votre écoute. Contactez-nous et ensemble, transformons vos
              aspirations en succès numériques.
            </p>
          </div>
        </div>
        <div className="right-container-contact">
          <div className="email-page-contact">
            <h3>Email :</h3>
            <p>contact@devoji.com</p>
          </div>
          <div className="phone-page-contact">
            <h3>Téléphone :</h3>
            <p>0782908853</p>
          </div>
          <div className="phone-page-contact">
            <h3>Adresse :</h3>
            <p>49 rue de Ponthieu</p>
            <p>75008 Paris</p>
          </div>
          <div className="square-page-contact">
            <p>
              Transformez vos idées en action, contactez-nous sans plus
              attendre.
            </p>
            <div className="loader" />
            <p>
              <a href="/mentions-legales">Mentions légales</a>
            </p>
          </div>
        </div>
      </div>
      <div>
        <img src={contact} alt="Devoji Logo" className="logo" />
      </div>
    </>
  );
};

export default Contact;
