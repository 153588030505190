import './Agence.scss';
import ExperienceComposant from './ExperienceComposant/ExperienceComposant';
import FondateurComposant from './FondateurComposant/FondateurComposant';

const Agence = () => {
  return (
    <div className="container-agence-top">
      <section className="agence-top">
        <p className="small-text-agence">
          DEVOJI
          <span className="yellow-square" />
        </p>
        <h1>
          Notre savoir-faire au service de la réalisation de vos objectifs
        </h1>
        <p className="p-title-agence-top">
          Chez DEVOJi, votre vision devient réalité. Notre expertise, enrichie
          par des années d&rsquo;expérience, est le moteur de votre succès. Un
          <span className="text-accompagnement"> accompagnement</span> pour{' '}
          <span className="text-grandir">grandit !</span>
        </p>
      </section>
      <div className="agence-top-image" />
      <section>
        <div className="agence-top-p">
          <p>
            Nous transformons vos besoins en réalité. Notre compétence
            approfondie en matière de transformation numérique, forgée à travers
            de nombreuses années d&rsquo;expérience, est le catalyseur de votre
            réussite. Nous sommes dédiés à la concrétisation de vos projets
            numériques - de la conception préliminaire à la mise en ligne de
            votre site, en passant par l&rsquo;optimisation continue de votre
            activité commerciale et stratégie de communication.
          </p>
          <p>
            Notre approche personnalisée s&rsquo;adapte aux besoins uniques de
            chaque client, qu&rsquo;il s&rsquo;agisse de petites entreprises ou
            de grands groupes. Spécialistes des CMS majeurs, nous sommes à vos
            côtés pour transformer vos idées en expériences digitales mémorables
            et efficaces. Faites confiance à DEVOJi pour naviguer dans le
            paysage numérique avec assurance et innovation.
          </p>
        </div>
      </section>
      <div>
        <ExperienceComposant />
        <FondateurComposant />
      </div>
    </div>
  );
};

export default Agence;
