import React from 'react';
import './CmsPageDetails.scss';
import {
  CmsDataItem,
  ContentItem,
  ListItem,
} from '../../Data/interface/cmsDataInterfaces';
import shopify from '../../assets/images/cmspage/Logo-Shopify.jpg';
import prestashop from '../../assets/images/cmspage/Prestashop-e-commerce.jpg';
import magento from '../../assets/images/cmspage/magento.jpg';
import woocommerce from '../../assets/images/cmspage/Site-e-commerce-Woo-Commerce.jpg';
import sylius from '../../assets/images/cmspage/sylius-symfony.jpg';

interface CmsPageDetailsProps {
  cmsData: CmsDataItem[];
  pageSlug: string;
}

const Heading: React.FC<{
  type: 'H1' | 'H2' | 'H3' | 'H4';
  content: string;
}> = ({ type, content }) => {
  const Tag = type.toLowerCase() as keyof JSX.IntrinsicElements;
  return <Tag>{content}</Tag>;
};

const Paragraph: React.FC<{ content: string }> = ({ content }) => (
  <p>{content}</p>
);

const UnorderedList: React.FC<{ items: ListItem[] }> = ({ items }) => (
  <ul>
    {items.map((item, index) => (
      <li key={item.id || index}>{item.content}</li>
    ))}
  </ul>
);

const CmsPageDetails: React.FC<CmsPageDetailsProps> = ({
  cmsData,
  pageSlug,
}) => {
  const pageData = cmsData.find((item) => item.slug === pageSlug);

  if (!pageData) {
    return <div>Page non trouvée</div>;
  }

  const renderContent = (item: ContentItem, key: number | string) => {
    switch (item.type) {
      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
        return (
          <Heading
            type={item.type}
            content={item.content as string}
            key={key}
          />
        );
      case 'p':
        return <Paragraph content={item.content as string} key={key} />;
      case 'ul':
        return <UnorderedList items={item.content as ListItem[]} key={key} />;
      default:
        return null;
    }
  };

  function getLogo(cmsKey: string): string {
    switch (cmsKey) {
      case 'magento':
        return magento;
      case 'boutique-shopify':
        return shopify;
      case 'prestashop-ecommerce':
        return prestashop;
      case 'woocommerce':
        return woocommerce;
      case 'sylius-symfony':
        return sylius;
      default:
        return '';
    }
  }

  return (
    <div className="cms-page-container">
      <div className={pageData.classname}>
        <img
          src={getLogo(pageSlug)}
          alt={`${pageData.slug} logo`}
          className="logo-cms-details"
        />
        {pageData.content.map((item, index) => renderContent(item, index))}
      </div>
    </div>
  );
};

export default CmsPageDetails;
