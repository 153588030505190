import { CmsDataItem } from './interface/cmsDataInterfaces';

const landingPage: CmsDataItem[] = [
  {
    id: 1,
    slug: 'interface-erp',
    classname: 'interface-erp',
    content: [
      {
        type: 'H1',
        content:
          'Interfaçage ERP : transformez votre entreprise avec notre service',
      },
      {
        type: 'p',
        content:
          'Êtes-vous prêt à libérer tout le potentiel de votre entreprise grâce à une intégration fluide et intelligente de vos systèmes informatiques ? Avec notre service d’interfaçage ERP, vous pouvez transformer votre entreprise en une machine bien huilée, prête à relever tous les défis du marché moderne.',
      },
      {
        type: 'H2',
        content: 'Qu’est-ce que l’interfaçage ERP ?',
      },
      {
        type: 'p',
        content:
          'Imaginez une synchronisation parfaite entre tous vos systèmes - de la gestion des stocks à la gestion des commandes, en passant par la comptabilité et bien plus encore. Fini les erreurs de saisie de données, les retards dans les processus et les décisions prises sur des informations obsolètes.',
      },
      {
        type: 'H2',
        content:
          'Avec notre équipe d’experts en interfaçage ERP, nous vous offrons :',
      },
      {
        type: 'p',
        content:
          'Une intégration transparente : Nous créons des ponts solides entre votre système ERP et tous vos autres systèmes, qu’ils soient internes ou externes. Fini les silos d’information - tout est connecté, tout est fluide.',
      },

      {
        type: 'p',
        content:
          'Une efficacité accrue : Libérez le plein potentiel de votre entreprise en automatisant les processus, en optimisant les flux de travail et en fournissant à vos décideurs des informations précises et en temps réel pour des décisions éclairées',
      },
      {
        type: 'p',
        content:
          ' Une sécurité renforcée : Vos données sont précieuses, c’est pourquoi nous mettons en place des mesures de sécurité robustes pour protéger chaque point de votre architecture informatique contre les menaces potentielles.',
      },
      {
        type: 'p',
        content:
          'Une flexibilité inégalée : Que vous soyez une petite start-up en pleine croissance ou une grande entreprise établie, notre service d’interfaçage ERP est conçu pour s’adapter à vos besoins spécifiques et évoluer avec vous.',
      },
      {
        type: 'p',
        content:
          'Ne laissez pas votre entreprise être freinée par des systèmes désynchronisés et des processus inefficaces. Choisissez notre service d’interfaçage ERP et ouvrez la voie à une croissance et à un succès durables.',
      },

      {
        type: 'p',
        content:
          'Contactez-nous dès aujourd’hui pour une consultation gratuite et découvrez comment nous pouvons vous aider à propulser votre entreprise vers de nouveaux sommets !',
      },
    ],
  },
  {
    id: 2,
    slug: 'interface-logistique',
    classname: 'interface-logistique',
    content: [
      {
        type: 'H1',
        content: 'Optimisez Votre Chaîne Logistique Avec Notre Expertise',
      },
      {
        type: 'p',
        content:
          'Dans le monde dynamique du commerce en ligne, la gestion efficace de la chaîne logistique est cruciale pour le succès de votre entreprise. Chez [Votre Entreprise], nous comprenons que chaque maillon de cette chaîne doit être parfaitement synchronisé pour garantir des opérations fluides et une satisfaction client maximale.',
      },
      {
        type: 'p',
        content:
          'Notre équipe dédiée est spécialisée dans l’interfaçage logistique, une expertise qui fait toute la différence. Mais qu’est-ce que cela signifie exactement ? Cela signifie que nous nous engageons à optimiser chaque étape de votre processus logistique, de la gestion des stocks à la livraison finale, en passant par la gestion des commandes et la gestion des retours.',
      },
      {
        type: 'p',
        content:
          'L’interfaçage logistique, c’est l’art de connecter les différents éléments de votre chaîne d’approvisionnement de manière transparente et efficace. Nous utilisons les dernières technologies et les meilleures pratiques de l’industrie pour garantir une intégration parfaite entre votre plateforme de commerce électronique, vos entrepôts, vos transporteurs et vos systèmes de gestion.',
      },
      {
        type: 'p',
        content:
          'Imaginez une chaîne logistique où chaque composant communique harmonieusement avec les autres, où les données sont synchronisées en temps réel, et où les processus sont automatisés pour une efficacité maximale. C’est exactement ce que nous offrons à nos clients.',
      },
      {
        type: 'H2',
        content:
          'En choisissant DEVOJI pour votre interfaçage logistique, vous bénéficiez de plusieurs avantages compétitifs :',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Efficacité accrue : En éliminant les goulets d’étranglement et en optimisant les processus, nous vous aidons à réduire les coûts opérationnels et à augmenter la productivité.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Satisfaction client améliorée : Des expéditions plus rapides, des mises à jour en temps réel sur les commandes et une gestion efficace des retours contribuent à fidéliser vos clients et à renforcer votre réputation.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Flexibilité et évolutivité : Notre approche modulaire vous permet de personnaliser nos solutions en fonction de vos besoins spécifiques, tout en vous offrant la possibilité de faire évoluer votre infrastructure logistique au fur et à mesure de la croissance de votre entreprise.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Expertise et support : Notre équipe d’experts est là pour vous accompagner à chaque étape, de la conception initiale à la mise en œuvre et au-delà. Nous sommes toujours disponibles pour répondre à vos questions et vous fournir un soutien personnalisé.',
          },
        ],
      },

      {
        type: 'p',
        content:
          'Ne laissez pas les défis logistiques freiner votre croissance. Contactez-nous dès aujourd’hui pour découvrir comment notre expertise en interfaçage logistique peut transformer votre entreprise et vous aider à atteindre de nouveaux sommets de succès.',
      },
    ],
  },
  {
    id: 3,
    slug: 'github-bitBucket',
    classname: 'github-bitBucket',
    content: [
      {
        type: 'H1',
        content:
          'La Collaboration et le Partage de Code avec GitHub et BitBucket',
      },
      {
        type: 'p',
        content:
          'GitHub et BitBucket sont deux plateformes essentielles pour les développeurs logiciels, chacune offrant des outils et des fonctionnalités uniques pour faciliter la collaboration, le partage de code et la gestion de projet dans le domaine du développement logiciel.',
      },
      {
        type: 'H2',
        content:
          'GitHub: La Plateforme Ultime pour le Développement Collaboratif',
      },
      {
        type: 'p',
        content:
          'GitHub est largement reconnu comme la plus grande plateforme d’hébergement de code au monde, permettant aux développeurs individuels et aux équipes de collaborer sur des projets de toute taille. Elle offre un système de gestion de versions basé sur Git, une interface utilisateur intuitive, ainsi que des fonctionnalités de suivi des problèmes, des demandes d’extraction et la gestion de projets agile. En plus de ces outils, GitHub encourage une culture du partage en rendant facile la découverte de projets open source et en facilitant la contribution à ceux-ci.',
      },
      {
        type: 'H2',
        content:
          'BitBucket: Intégration et Sécurité pour les Équipes de Développement',
      },
      {
        type: 'p',
        content:
          'BitBucket, de son côté, est une solution qui s’intègre profondément avec les autres outils de la suite Atlassian, comme Jira, Confluence et Bamboo. Elle est particulièrement adaptée pour les équipes qui utilisent déjà ces outils pour la gestion de projets et le déploiement continu. BitBucket met l’accent sur la confidentialité et la sécurité, offrant des répertoires privés gratuits pour les petites équipes, ainsi que des fonctionnalités avancées pour les équipes de développement d’entreprise.',
      },
      {
        type: 'H3',
        content: 'Transition vers des Pratiques de Développement Collaboratif',
      },
      {
        type: 'p',
        content:
          'L’adoption de ces plateformes peut représenter un changement significatif pour les équipes non familiarisées avec les pratiques de développement collaboratif et les systèmes de gestion de versions. C’est pourquoi l’accompagnement et la formation des personnes à ces outils deviennent cruciaux. Des sessions de formation peuvent être organisées pour couvrir les fondamentaux de Git, les meilleures pratiques de collaboration sur GitHub ou BitBucket, ainsi que des ateliers sur les fonctionnalités spécifiques comme les pull requests, la gestion des branches, et l’intégration continue.',
      },
      {
        type: 'p',
        content:
          'Cette formation peut prendre diverses formes, allant des ateliers pratiques en petits groupes, des sessions de coaching individuel, à des cours en ligne pour permettre une flexibilité maximale. L’objectif est de s’assurer que tous les membres de l’équipe sont à l’aise avec ces outils et peuvent les utiliser efficacement dans le cadre de leurs projets, favorisant ainsi une culture de collaboration et d’innovation au sein de l’organisation.',
      },
    ],
  },
  {
    id: 4,
    slug: 'seo-referencement-naturel',
    classname: 'seo-referencement-naturel',
    content: [
      {
        type: 'H1',
        content: 'Découvrons le référencement naturel',
      },
      {
        type: 'p',
        content:
          'On vous en a déjà parlé, on vous dit que c’est important mais savez-vous ce que c’est le SEO ?, Pourquoi faire du référencement ? À quoi bon référencer un site sur Google ?',
      },
      {
        type: 'p',
        content:
          'Pour commencer le SEO veut dire Search Engine Optimization, c’est-à-dire l’optimisation des moteurs de recherche. Concrètement, les contenus que vous allez publier sur votre site internet ne seront pas forcément vus par des moteurs de recherche et ne seront pas forcément indexés par ceux-ci ou mal indexés.',
      },
      {
        type: 'H2',
        content: 'Comprendre le Référencement Naturel',
      },
      {
        type: 'p',
        content:
          'Pour comprendre, Google référence un site Internet avec 3 critères principaux. Tout d’abord, Google envoie ses robots sur la toile pour qu’ils parcourent le Web. Ils vont crawler, scanner et aspirer les pages de tous les sites qu’ils trouvent. Par rapport aux données qu’obtiennent les robots, Google va analyser et classer vos différentes pages. Il est donc fortement recommander de faciliter la tâche de ces robots en suivant les règles fondamentales des moteurs de recherche.',
      },
      {
        type: 'p',
        content:
          'Les enjeux du référencement naturel sont très importants car c’est un levier incontournable pour développer votre site internet.  Avec le référencement naturel, votre site sera visible 24h/ 24, et 7 j/7 en un seul clic. ',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Optimisation Technique: La base d’une stratégie SEO est d’avoir un site Web répondant techniquement au exigence des moteurs de recherches : optimisation de l’architecture et du balisage, page 404, optimisation des images, du temps de chargement, accompagnement lors de refonte et maintenance SEO… ',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Analyse du trafic : Avoir des visiteurs c’est bien mais avoir des visiteurs qualifiés, qui sont intéressés par vos services ou vos produits est beaucoup plus intéressant. Pour mieux comprendre vos besoins, nous étudions le comportement des internautes qui visitent votre site.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Netlinking : L’algorithme de Google calcule la réputation de votre site web en partie avec les liens entrants. Il s’agit d’un travail délicat, certains liens être négatif. Nous utilisons différents leviers comme les partenariats blogueurs, link baiting, soumission aux annuaires de qualité.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Nettoyage de Liens Nocifs : Cette partie est complémentaire au Netlinking. Google fait la chasse aux liens factices en infligeant des sanctions aux sites utilisant ces techniques. A la découverte d’un lien toxique, nous sollicitons les webmasters pour retirer votre lien nocif ou nous désapprouvons le lien auprès de Google.',
          },
          {
            id: 5,
            type: 'li',
            content:
              'Formation SEO : Le SEO doit être travaillé le plus souvent possible, voir quotidiennement. La personne qui est le plus apte à le faire, c’est vous. Notre équipe peut se charger de vous former en continue ou lors de sessions individuelles ou collectives. Confiez votre formation SEO à nos collaborateurs.',
          },
          {
            id: 6,
            type: 'li',
            content:
              'Référencement mobile : Le référencement mobile tient une place de plus en plus importante pour Google. Il rassemble toutes les techniques d’optimisation d’un site destiné à être affiché sur les smartphones et tablettes. Google, sait bien que nous recherchons de plus en plus avec notre smartphone et pour répondre à cela, il a créé l’indexation mobile first.',
          },
        ],
      },
      {
        type: 'H2',
        content:
          'Votre Agence SEO vous aide à choisir les mots-clés qui génèrent des ventes !',
      },
      {
        type: 'p',
        content:
          'N’optimisez pas votre site web pour des mots-clés inutiles. Si tout le monde obtient de bons résultats pour eux, s’ils ont un taux de recherche élevé, cela ne signifie pas forcément qu’ils sont rentables pour vous.',
      },
      {
        type: 'H2',
        content:
          'Dans le référencement naturel, nous commençons avec un Audit SEO',
      },
      {
        type: 'p',
        content:
          'Avant d’entreprendre quelconques modifications, vous devez connaître les points stratégiques que vous devez travailler sur votre site internet. Il est recommandé d’effectuer régulièrement un audit SEO. Il peut être entrepris à n’importe quelle étape du cycle de vie du votre site web. ',
      },
      {
        type: 'p',
        content:
          'Son objectif est d’identifier d’éventuels problèmes techniques et les axes d’amélioration. Lors d’un audit SEO, nous contrôlons plusieurs points comme les URL, les liens, les logs, les redirections, les mots-clés, les aspects sémantiques, les contenus, les sitemaps, les balises title, la popularité etc. ',
      },
      {
        type: 'p',
        content:
          'Pour auditer un site, il faut être polyvalent ou bien entouré. Il faut connaitre différents domaines, comprendre un peu la technique, il faut aussi savoir rédiger. D’un point de vue général, il faut avoir de véritables compétences marketing.',
      },
    ],
  },
  {
    id: 5,
    slug: 'marketplaces-guides-shopping',
    classname: 'marketplaces-guides-shopping',
    content: [
      {
        type: 'H1',
        content: 'Marketplaces / Guides Shopping',
      },
      {
        type: 'H2',
        content: 'Analyse, paramétrage et optimisation de vos flux',
      },
      {
        type: 'p',
        content:
          'DEVOJI a vocation de vous accompagner dans le développement de votre business sur les Marketplaces et sur les guides shopping. Que vous soyez gérant de boutique, importateurs, simple petit commerçant, Pure Player ou vous disposez aussi de magasins physique, il est nécessaire de publier vos offres sur des sites leader et à forte audience.',
      },
      {
        type: 'p',
        content:
          'La marketplace ou le guide shopping vous permet d’élargir rapidement et sans effort vos réseaux de distribution, vous devez l’incorporer dans votre développement digital. Ces canaux et outils de communication sont une perspective de croissance rapide et très rentable qu’il ne faut pas négliger. Pour profiter pleinement de leurs visibilités, vous devez avoir des connaissances, notamment pour configurer les flux, gérer les règles, les mappings ou les attributs afin d’éviter les déconvenues.',
      },
      {
        type: 'p',
        content:
          'Autre point, il est important de ne pas être dépendant d’un canal de diffusion, il est donc nécessaire d’avoir une stratégie global de votre développement digital.',
      },
      {
        type: 'H2',
        content: 'Les places de Marché (Marketplaces) pour booster son CA',
      },
      {
        type: 'p',
        content:
          'Lors du lancement de votre E-shop ou si vous désirez donner un coup de pouce à votre business, le levier des Marketplaces est une solution à privilégier pour générer un chiffre d’affaires additionnel.. Cependant, pour éviter les problèmes où des sociétés ont dû baisser le rideau à cause de la mauvaise gestion de ce canal, il est préférable de ne pas devenir dépendant et de faire appel à des spécialistes.',
      },
      {
        type: 'p',
        content:
          'Les Places de Marché sur Internet (Marketplaces) représentent une option à ne pas négliger pour développer son activité. Ces centres commerciaux virtuels permettent à n’importe quel vendeur de proposer ses produits sur les sites leader. Il y a différentes Marketplaces, généralistes ou sur des thématiques précises (Sport, Décoration, Jardin, pour seniors, cadeaux…). La Marketplace est une plateforme jouant le rôle d’intermédiaire entre des vendeurs et des acheteurs potentiels. Le niveau d’audience de ces plateformes est très élevé, ce qui permet à vos produits d’être visibles par la majorité des e-consommateurs français et européens.',
      },
      {
        type: 'H3',
        content: 'DEVOJI, Expert Marketplaces',
      },
      {
        type: 'p',
        content:
          'Les cofondateurs ont une grande connaissance dans le développement et la gestion des Marketplaces. Ils ont partagé leur expérience d’e-commerçant (8 ans), où ils géraient 17 places de marché à toute l’équipe. DEVOJI vous accompagne et vous recommande la bonne stratégie pour augmenter votre CA sans devenir dépendant de ce canal.',
      },
    ],
  },
  {
    id: 6,
    slug: 'configuration-serveur',
    classname: 'configuration-serveur',
    content: [
      {
        type: 'H1',
        content: 'Configuration serveur',
      },
      {
        type: 'H2',
        content: 'Administration Système Linux Infogérance',
      },
      {
        type: 'p',
        content:
          'Votre configuration serveur est un élément qui peut changer clairement la vie de votre site internet. La vitesse de chargement des pages web de votre site internet est très importante. Avec le Mobile First Index, Google met une attention supplémentaire à ce sujet. La vitesse de votre site web impacte de nombreux points, aussi bien au niveau expérience du visiteur que du côté du référencement ou campagne publicitaire. Il y a donc un fort intérêt à optimiser les performances de votre serveur.',
      },
      {
        type: 'p',
        content:
          'L’objectif de l’infogérance est d’externaliser toutes les démarches informatiques de votre société. Ne perdez plus de temps à configurer vos serveurs, DEVOJI devient votre administrateur système. Nous pouvons gérer votre parc informatique pour que vous vous concentriez sur votre coeur de métier.',
      },
      {
        type: 'H2',
        content: 'Configuration serveur / Pourquoi optimiser son serveur ?',
      },
      {
        type: 'p',
        content:
          'Un site trop lent donne une mauvaise expérience à l’internaute qui le visite. Nous sommes dans un monde où la patience n’est pas une vertu, nous exigeons l’information tout de suite, instantanément sans attente et dans le web, c’est la même chose. Un site lent envoie un mauvais signal au visiteur et l’internaute ne reviendra pas sur votre site, si le chargement est trop lent. Des études ont montré que le chargement d’un site influait directement sur le nombre de pages vues par le visiteur et le taux de transformation.',
      },
      {
        type: 'p',
        content:
          'La seconde raison est sur l’impact SEO. On dit qu’un site doit avoir moins d’une seconde de chargement au niveau d’un site mais en réalité, il est recommandé d’être en dessous des 300-350 ms. Chaque moteur de recherche possède un robot qui vient crawler votre site régulièrement pour l’indexer. Si le bot est ralenti par votre vitesse, le moteur de recherches vous classera moins bien ou pas du tout dans ses résultats de recherche.',
      },
      {
        type: 'H2',
        content:
          'La vitesse d’affichage et l’expérience utilisateur intimement liées.',
      },
      {
        type: 'p',
        content:
          'L’optimisation d’un site internet est de loin un critère très important, notre équipe intervient dans l’installation de votre serveur linux et la configuration de votre site avec Apache ou Nginx. L’optimisation d’un serveur nécessite des connaissances techniques et il est recommandé de faire appel à des experts pour ces tâches. La sécurité est aussi importante, une mauvaise configuration peut rendre votre site vulnérable et par conséquence être piraté.',
      },
      {
        type: 'H2',
        content: 'Configuration Serveur : Devoji l’optimise mais comment ?',
      },
      {
        type: 'H3',
        content: 'L’HÉBERGEMENT WEB',
      },
      {
        type: 'p',
        content:
          'Choix selon vos besoins, mutualisé, dédiées, cloud, virtualisées...L’hébergement mutualisé est un modèle d’hébergement Web dans lequel de nombreux sites Internet sont regroupés. Serveur dédié, l’ensemble des ressources disponibles sur la machine sont allouées à un même client.',
      },
      {
        type: 'H3',
        content: 'CONFIGURATION LOGICIELLE',
      },
      {
        type: 'p',
        content:
          'Généralement, l’hébergement sous Linux avec le trio Apache, Php et MySql. Apache est un serveur HTTP, PHP un interpréteur de langage, et MySql un système de base de données. Il existe des logiciels alternatifs comme Nginx, IIS, Python, ASP, PostgreSQL, etc. Il faut que votre logiciel soit bien configuré pour pouvoir traiter un maximum de requêtes sans pour autant changer le serveur physique.',
      },
      {
        type: 'H3',
        content: 'LE CDN DANS LA CONFIGURATION SERVEUR',
      },
      {
        type: 'p',
        content:
          'Le principe du CDN (Content Delivery Network) est un réseau de diffusion de contenu. C’est-à-dire que les données sont rapidement chargées selon l’endroit d’où provient la requête dans le monde. Il permet de garder en cache suivant vos règles les fichiers qui ne nécessitent, pas de mise à jour permanente comme les images produit, documents PDF. Le CDN permet d’alléger le serveur web qui ne s’occupe plus alors que de travailler les ressources dynamiques.',
      },
      {
        type: 'H3',
        content: 'SYSTÈME DE CACHE SERVEUR',
      },
      {
        type: 'p',
        content:
          'Pour faire fonctionner votre serveur, vous avez besoin d’un logiciel d’interprétation de langage comme Php. De base, ce logiciel consomme les ressources serveurs, c’est pour cela que vous devez faire appel à des caches serveur. L’objectif principal d’un cache est d’ugmenter les performances de récupération des données',
      },
      {
        type: 'H3',
        content: 'SOUS-DOMAINES POUR LES ASSETS',
      },
      {
        type: 'p',
        content:
          'Une autre optimisation pour les médias est la création de sous-domaines dédiés aux assets. C’est-à-dire qu’il faut placer plusieurs sous-domaines pour vos images par exemple, cela va vous permettre de démarrer plusieurs groupes de connexions HTTP en simultanée au lieu de connexion par sous-domaine (4, 6, 8…) en file d’attente.',
      },
      {
        type: 'H3',
        content: 'COMPRESSION DES PAGES',
      },
      {
        type: 'p',
        content:
          'Plus votre site grandi, plus il va être lourd et ralentir, il est donc indispensable de compresser les données. Par exemple avec linux, on utilise très généralement la compression Gzip et/ou le mod deflate mais il existe plusieurs méthodes de compression disponible sur la plupart des systèmes.',
      },
      {
        type: 'H3',
        content: 'BASES DE DONNÉES',
      },
      {
        type: 'p',
        content:
          'Les bases de données hébergent vos contenus comme vos articles, produit, base client, configurations, etc. Avec le temps, au fur et à mesure qu’elles travaillent, elle grandissent et doivent être optimisées.',
      },
      {
        type: 'H3',
        content: 'CACHE DU SITE',
      },
      {
        type: 'p',
        content:
          'Le cache de votre site est une partie très importante. Vous devez mettre en place un système de cache soit par fichier soit par base de données pour éviter à faire appel à des ressources sur le serveur qui ne le nécessitent pas.',
      },
      {
        type: 'H3',
        content: 'LE CSS ET LES SCRIPTS',
      },
      {
        type: 'p',
        content:
          'Les fichiers CSS et les Script du style Javascript, etc… sont des ressources à optimiser. Il faut être en mesure d’utiliser seulement les données utiles pour que le serveur est moins de chose à traiter et à charger..',
      },
      {
        type: 'H3',
        content: 'LES IMAGES DANS VOTRE CONFIGURATION SERVEUR',
      },
      {
        type: 'p',
        content:
          'Sauf si vous êtes photographe et vous désirez mettre en avant vos clichés originaux, il n’est pas nécessaire d’avoir une résolution d’image trop importante. Les images trop grosses ralentissent la navigation, le chargement, etc.',
      },
      {
        type: 'H3',
        content: 'POIDS DES PAGES',
      },
      {
        type: 'p',
        content:
          'Ce point logique et essentielle, il faut analyser et ne pas alourdir vos pages. Devoji vous aidera à créer des pages légères et efficace. Il faut faire attention à l’utilisation des sliders, des vidéos, de la pub, du flash, etc. Notre équipe vous apportera des solutions.',
      },
      {
        type: 'H3',
        content: 'TRAITEMENTS RÉCURRENTS',
      },
      {
        type: 'p',
        content:
          'Il est recommandé d’éviter plusieurs appels de la même fonction dans une page web ou d’avoir des widgets trop long à charger. Plus il y a de choses à traiter sur une page, plus le chargement sera long.',
      },
      {
        type: 'H3',
        content: 'LE CODE',
      },
      {
        type: 'p',
        content:
          'Plus votre code est propre, plus vos pages vont se charger vite. Esthétiquement, une page web mal codée ne diffère pas beaucoup d’une page web bien codée. Mais les lignes de codes inutiles pénalisent les performances de votre site.',
      },
    ],
  },
  {
    id: 7,
    slug: 'developpement-web',
    classname: 'developpement-web',
    content: [
      {
        type: 'H1',
        content: 'Développement web sur-mesure',
      },
      {
        type: 'p',
        content: 'Une idée, un projet ? DEVOJI vous accompagne !',
      },
      {
        type: 'H2',
        content:
          'DEVOJI vous conseille et développe votre projet en un temps record.',
      },
      {
        type: 'p',
        content:
          'DEVOJI  est votre partenaire  WEB. Vous avez un projet, une évolution à effectuer pour votre activité et vous n’avez pas de développeur, notre équipe est capable de répondre aux demandes les plus complexes en vous apportant leurs expertises et leurs solutions. Notre équipe maîtrise différents langages et s’adapte aux solutions techniques existantes en fonction du projet et de votre demande.',
      },
      {
        type: 'p',
        content:
          'Nos développeurs web sont polyvalents, autonomes, respectueux des délais et capable d’intégrer de nouveaux concepts et langages de programmation dans cet écosystème qui évolue très rapidement.',
      },
      {
        type: 'p',
        content:
          'DEVOJI  est à votre écoute pour tous vos projets web,  mobiles ou autre développement web. Notre expérience sous plusieurs CMS (Magento, Prestashop, Worpress…) et nos différentes missions avec nos clients permettent de vous garantir une adaptabilité aisée à votre projet.',
      },
      {
        type: 'p',
        content:
          'Expliquez-nous votre projet et nous vous accompagnerons dans sa réalisation.',
      },

      {
        type: 'H2',
        content:
          'Développement Web Comment l’agence de développement DEVOJI fonctionne ?',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Analyse des besoins : Nous analysons les besoins de votre projet ainsi que les solutions techniques en place ou à mettre en place. C’est l’étape du cahier des charges.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Validation : Validation : dans cette phase, nous validons les besoins et les délais suivant les éléments communiqués en amont dans le cahier des charges.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Développement : Nous entrons dans la phase de développement. Cette phase permet d’effectuer des ajustements dans les objectifs établis, selon les problèmes rencontrés.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Test & Mise en production : Le développement est testé tout au long du projet. Les éventuelles erreurs sont corrigées en collaboration avec les webdesigners et autres développeurs avant une mise en production qui précédera le lancement final du site.',
          },
          {
            id: 5,
            type: 'li',
            content:
              'Support technique : Une fois les développements en ligne, il faudra assurer un support en cas de dysfonctionnement ou encore pour effectuer des mises à jour.',
          },
        ],
      },
      {
        type: 'H2',
        content: 'Pourquoi faire appel à un développeur Web ?',
      },
      {
        type: 'p',
        content:
          'Pour un CMS comme Prestashop, Magento, WordPress… ou pour un développement web spécifique, prendre un développeur est parfois obligatoire. Si dans votre équipe, vous n’avez pas les compétences nécessaires pour réaliser votre projet, vous devrez embaucher un développeur ou faire appel à des compétences extérieures.',
      },
      {
        type: 'p',
        content:
          'L’embauche peut s’avérer compliquée parce que les besoins peuvent être simplement ponctuel pour un projet. Le coût des développeurs est un frein puisqu’il y a une pénurie de ressources vis-à-vis de la demande croissante. C’est pourquoi l’externalisation est une solution à privilégier dans cette situation.',
      },
      {
        type: 'H3',
        content: 'Developpement Web : trouver un développeur',
      },
      {
        type: 'p',
        content:
          'Trouver un développeur est une étape également complexe, vous devrez être capables d’expliquer vos besoins et vos projets d’un point de vue technique pour trouver la bonne ressource. L’informatique et le web sont des domaines précis qui ne sont pas forcément votre apanage. Vous êtes confronté à une potentielle perte de temps conséquente donc un manque à gagner pour votre entreprise. Vous devez rester concentré sur votre cœur de métier.',
      },
      {
        type: 'p',
        content:
          'DEVOJI ont des programmeurs qui maîtrisent les techniques de développement, ce qui permet d’être très réactif pour commencer votre projet au plus tôt. Faire appel à une agence WEB permet aussi d’être assuré d’un suivi dans le temps.',
      },
      {
        type: 'H2',
        content: 'Développement Web spécifique',
      },
      {
        type: 'p',
        content:
          'Les solutions prépackagées du marché ne répondent pas à vos besoins; la fonctionnalité qui permettra de faire évoluer votre site internet n’existe pas sur votre CMS (PrestaShop, Magento, WordPress….). Notre agence web développe des solutions sur mesure qui répondent au besoin de chaque projet.',
      },
      {
        type: 'p',
        content:
          'DEVOJI possède les compétences et les outils pour travailler sur tous types de projets Web et Digital et cela sans aucune limite de réalisation.',
      },
      {
        type: 'p',
        content:
          'Par son expérience et son professionnalisme, DEVOJI saura vous conseiller, vous orienter et développer les solutions qui répondent à vos besoins.',
      },
    ],
  },
  {
    id: 8,
    slug: 'symfony',
    classname: 'symfony',
    content: [
      {
        type: 'H1',
        content: 'Découvrez notre expertise Symfony',
      },
      {
        type: 'H2',
        content: 'Pourquoi Symfony ?',
      },
      {
        type: 'p',
        content:
          'Symfony est bien plus qu’un simple framework de développement web. Il offre une structure solide et des fonctionnalités avancées qui permettent de créer des applications web robustes et évolutives.',
      },
      {
        type: 'H2',
        content: 'Notre engagement envers Symfony',
      },
      {
        type: 'p',
        content:
          'En tant qu’experts Symfony, nous nous engageons à fournir des solutions sur mesure et de qualité supérieure à nos clients. Notre équipe de développeurs chevronnés maîtrise parfaitement ce framework et est constamment à l’affût des dernières innovations pour garantir le succès de votre projet.',
      },
      {
        type: 'H2',
        content: 'Nos services Symfony',
      },
      {
        type: 'p',
        content:
          'Que vous ayez besoin de développer une plateforme web complexe, un système de gestion de contenu personnalisé ou une application mobile interconnectée, notre équipe possède l’expertise nécessaire pour concrétiser votre vision. De la planification initiale à la livraison finale, nous offrons une gamme complète de services Symfony pour répondre à tous vos besoins en matière de développement web',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Développement sur Mesure : Notre équipe de développeurs Symfony est spécialisée dans la création d’applications web sur mesure qui répondent précisément à vos besoins. Nous commençons par comprendre vos exigences et vos objectifs commerciaux, puis nous concevons et développons une solution Symfony personnalisée qui optimise votre efficacité opérationnelle et améliore votre expérience utilisateur. ',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Migration et Mise à Niveau : Vous cherchez à migrer votre application vers Symfony ou à mettre à niveau vers une version plus récente du framework ? Nous sommes là pour vous aider. Notre équipe expérimentée assure une transition fluide et sans heurts, en veillant à ce que toutes vos données et fonctionnalités soient préservées tout au long du processus.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Optimisation des Performances : La performance est essentielle pour le succès de toute application web. Nous optimisons votre application Symfony pour garantir une vitesse de chargement rapide, une réactivité maximale et une expérience utilisateur fluide sur tous les appareils et plateformes. Nous utilisons des techniques avancées d’optimisation des performances pour maximiser l’efficacité de votre application et réduire les temps de chargement.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Sécurité Renforcée : La sécurité est notre priorité absolue. Nous mettons en œuvre les meilleures pratiques de sécurité Symfony pour protéger votre application contre les menaces potentielles, telles que les attaques par injection SQL, la falsification de requêtes inter-sites (CSRF) et les vulnérabilités XSS. Notre approche proactive en matière de sécurité garantit la confidentialité, l’intégrité et la disponibilité de vos données à tout moment.',
          },
          {
            id: 5,
            type: 'li',
            content:
              'Maintenance et Support : Une fois votre application Symfony déployée, notre travail ne s’arrête pas là. Nous offrons des services complets de maintenance et de support pour garantir le bon fonctionnement continu de votre application. De la résolution des bogues à l’application des mises à jour de sécurité, notre équipe est disponible pour répondre à tous vos besoins en matière de maintenance et de support Symfony.',
          },
        ],
      },
      {
        type: 'H2',
        content: 'Les avantages de travailler avec nous',
      },
      {
        type: 'p',
        content:
          'En choisissant [Votre Nom de Société] pour votre projet Symfony, vous bénéficiez d’une expertise technique de haut niveau, d’une attention méticuleuse aux détails et d’une approche centrée sur le client. Notre engagement envers la qualité et l’innovation garantit que votre projet Symfony sera réalisé avec succès, dans les délais impartis et selon vos spécifications exactes.',
      },
      {
        type: 'H2',
        content: 'Parlons de votre projet Symfony',
      },
      {
        type: 'p',
        content:
          'Prêt à donner vie à votre projet Symfony ? Contactez-nous dès aujourd’hui pour planifier une consultation initiale. Notre équipe est impatiente de discuter de vos besoins spécifiques et de collaborer avec vous pour créer une solution sur mesure qui répond à vos objectifs commerciaux et dépasse vos attentes.',
      },
    ],
  },
  {
    id: 9,
    slug: 'react-js',
    classname: 'react-js',
    content: [
      {
        type: 'H1',
        content: 'Découvrez React JS avec DEVOJI',
      },
      {
        type: 'H2',
        content: 'Pourquoi React JS ?',
      },
      {
        type: 'p',
        content:
          'React JS offre une approche déclarative pour créer des composants d’interface utilisateur dynamiques, ce qui simplifie le processus de développement et améliore la maintenabilité du code.',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Performance et Réactivité : React JS utilise une approche de rendu virtuel qui améliore considérablement les performances des applications web en minimisant les manipulations directes du DOM. Cela se traduit par une expérience utilisateur plus fluide et réactive.',
          },
          {
            id: 2,
            type: 'li',
            content:
              ' Composabilité et Modularité : React JS encourage la création de composants réutilisables et modulaires, ce qui facilite le développement, la maintenance et l’évolutivité des applications. Cette approche favorise également une architecture propre et bien organisée.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Développement Déclaratif : Grâce à sa syntaxe déclarative, React JS simplifie le processus de développement en permettant aux développeurs de se concentrer sur la logique métier plutôt que sur la manipulation du DOM. Cela accélère le cycle de développement et réduit les risques d’erreurs.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Large Adoption et Écosystème Actif : React JS bénéficie d’une large adoption dans l’industrie, soutenue par une communauté active et un écosystème riche en outils, bibliothèques et composants prêts à l’emploi. Cela garantit une évolution constante et des solutions pour répondre à divers besoins.',
          },
        ],
      },
      {
        type: 'H2',
        content: 'Nos Services React JS',
      },
      {
        type: 'p',
        content:
          'Nous sommes spécialisés dans l’utilisation de React JS pour développer des applications web et mobiles modernes, offrant des performances optimales et une expérience utilisateur exceptionnelle.',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Développement sur mesure : Nous concevons et développons des applications web et mobiles sur mesure en utilisant les meilleures pratiques de React JS, garantissant des performances optimales et une expérience utilisateur exceptionnelle.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Migration et Mise à Niveau : Nous facilitons la migration de votre application vers React JS ou la mise à niveau d’une version existante, assurant une transition fluide et sans heurts tout en préservant la fonctionnalité et les données.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Optimisation des Performances : Nous optimisons vos applications React JS pour des chargements rapides, une réactivité maximale et une efficacité accrue, garantissant ainsi une expérience utilisateur incomparable quel que soit le dispositif utilisé.',
          },
          {
            id: 4,
            type: 'li',
            content:
              'Consultation et Support Technique : Notre équipe d’experts en React JS est disponible pour fournir des conseils personnalisés, des audits de code et un support technique continu pour assurer le succès à long terme de votre projet.',
          },
        ],
      },

      {
        type: 'H2',
        content: 'React JS avec Redux, TypeScript et Eslint ?',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Gestion de l’État avec Redux : En combinant React JS avec Redux, nous offrons une gestion de l’état efficace et prévisible pour vos applications. Redux fournit un flux de données unidirectionnel qui simplifie la gestion des données complexes et facilite le débogage.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Typage Fort avec TypeScript : En adoptant TypeScript avec React JS, nous apportons une couche de typage statique au développement d’applications, ce qui permet une détection précoce des erreurs et une meilleure maintenance du code. TypeScript offre une expérience de développement plus sécurisée et évolutive.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Standardisation du Code avec Eslint : Eslint est un outil essentiel pour garantir la qualité du code et maintenir des normes élevées de développement. En intégrant Eslint à notre flux de travail, nous assurons la cohérence du code, détectons les erreurs potentielles et favorisons les bonnes pratiques de programmation.',
          },
        ],
      },

      {
        type: 'H2',
        content: 'Prêt à Passer à React JS ?',
      },
      {
        type: 'p',
        content:
          'Prêt à découvrir les avantages de React JS pour votre projet ? Contactez-nous dès aujourd’hui pour en savoir plus sur la façon dont nous pouvons vous aider à réaliser vos ambitions numériques avec cette technologie innovante.',
      },
    ],
  },
  {
    id: 10,
    slug: 'formations-webmarketing',
    classname: 'formations-webmarketing',
    content: [
      {
        type: 'H1',
        content: 'Formations Webmarketing',
      },
      {
        type: 'H2',
        content: 'Accompgnement E-commerce',
      },
      {
        type: 'p',
        content:
          'DEVOJI propose des formations de présentation, de conseils, de formations et d’accompagnement dans les métiers du e-commerce, de la bureautique et de la gestion d’entreprise. Elles sont très souvent animées par les cofondateurs et des intervenants du monde digital ou de grandes entreprises y sont invitées afin de partager leur expérience. ',
      },
      {
        type: 'p',
        content:
          'Dans nos formations Webmarketingn nous partageons notre expérience acquise dans le monde Digital et en tant qu’entrepreneur. Nos différentes formations permettent donc de vous faire gagner du temps en vous transmettant nos connaissances et en vous faisant partager les erreurs lors de nos premières années d’entrepreneuriat.',
      },
      {
        type: 'H3',
        content: 'Formations business',
      },
      {
        type: 'p',
        content:
          'E-commerce, E-marketing, gestion d’entreprise, comment trouver son associé, la création d’entreprise… nous abordons différents domaines, notamment ceux qui peuvent vous faire gagner du temps. Le E-commerce est en croissance perpétuelle, il nécessite donc des compétences et des spécialisations dans des domaines techniques. Par conséquent, vous devez vous initier dans le développement informatique, mais aussi dans le marketing qui sont aussi en pleine évolution à l’image de la transformation digitale de la société et de toutes ses composantes.',
      },
      {
        type: 'H2',
        content: 'Des formations Webmarketing pour s’adapter au marché',
      },
      {
        type: 'p',
        content:
          'Il est très important de se former et de former ses équipes. Cela permet de gagner du temps et d’accélérer vos projets. Nous proposons aussi bien des formations individuelles que collectives. Nous intervenons dans différents endroits comme dans des salles partenaires, dans vos locaux ou à distance. ',
      },
      {
        type: 'p',
        content:
          'La méthode pédagogique est variable de façon à s’adapter aux différents publics rencontrés. L’objectif étant de renforcer les compétences de vos collaborateurs afin de donner à chacun le moyen d’exercer encore mieux son métier. En effet, la formation est un véritable levier de la stratégie de l’entreprise. Elle peut aussi être considérée comme une récompense ou un signe de reconnaissance en vers vos employés. Une formation permet également d’acquérir les bases d’un sujet afin de comprendre les devis afin de choisir au mieux son prestataire avec qui travailler.',
      },
      {
        type: 'p',
        content:
          'Nous animons régulièrement des échanges dans les immeubles de coworking à Paris mais nous pouvons organiser, selon le nombre de participants, des présentations, des formations Webmarketing dans toutes la France et Belgique (Formation exclusivement en Français).',
      },
      {
        type: 'H2',
        content: 'Conseils / Formations / Accompgnement E-commerce',
      },
      {
        type: 'p',
        content:
          'Face à un monde des affaires qui s’accélère et à la digitalisation de plus en plus indispensable, les dirigeants d’entreprise et leurs équipes doivent notamment maîtriser les évolutions techniques du Web.      DEVOJI accompagne la transformation de votre entreprise en formant vos collaborateurs dans vos changements stratégiques digitaux.',
      },
      {
        type: 'p',
        content:
          'E-commerce, E-marketing, gestion d’entreprise, comment trouver son associé, la création d’entreprise… nous abordons différents domaines qui peuvent vous faire gagner du temps.     Nous proposons également des formations à distance qui s’adaptent à vos disponibilités, votre environnement et votre profil d’apprentissage.',
      },
    ],
  },
  {
    id: 11,
    slug: 'site-wordpress',
    classname: 'site-wordpress',
    content: [
      {
        type: 'H1',
        content: 'Site vitrine WordPress',
      },
      {
        type: 'p',
        content:
          'WordPress est un terme souvent rencontré dans le domaine du web. En effet, il s’agit de l’un des CMS (Content Management System) les plus utilisé au monde. Actuellement 30,6 % des sites web sur la toile sont des sites WordPress. Le succès de WordPress lui vient de sa facilité de prise en main et de son accessibilité. Il s’agit d’un CMS gratuit et open-source. Sa polyvalence est également une des grandes qualités de WordPress. Il convient aussi bien aux sites web de type blog, qu’aux sites de vente en ligne. L’interface de WordPress comprend 8 principales rubriques. À savoir : « Tableau de bord », « Articles », « Médias », « Pages », « Commentaires », « Apparence », « Extensions », « Utilisateurs » et « Réglages ».',
      },
      {
        type: 'H3',
        content: 'Tableau de bord',
      },
      {
        type: 'p',
        content:
          'La première rubrique du menu de WordPress est le « Tableau de bord ». Il s’agit en quelque sorte du récapitulatif de votre site web ainsi que des différentes opérations que vous pouvez effectuer. Ce tableau de bord vous servira à visualiser les informations importantes concernant l’activité de votre site internet. Par exemple le nombre de commentaires, le nombre d’articles publiés et le nombre de pages créées.',
      },
      {
        type: 'p',
        content:
          'Vous pourrez notamment consulter ces éléments directement depuis le tableau de bord. Il vous permettra aussi de gérer et créer les menus de votre site web, d’ajouter des pages, et même des articles. De cet endroit, vous pouvez aussi mettre à jour les différents éléments de votre site, comme les pluggins et les différents thèmes.',
      },
      {
        type: 'p',
        content:
          'Sachez également qu’il est possible de personnaliser le tableau de bord de WordPress, notamment en ce qui concerne la disposition des différents blocs selon vos besoins. Vous pourrez mettre en avant les informations qui vous semble les plus importantes dès l’ouverture du tableau de bord.',
      },
      {
        type: 'H2',
        content: 'La rubrique Articles',
      },
      {
        type: 'p',
        content:
          'La rubrique « Articles » est la seconde du menu principal de WordPress. Comme son nom l’indique, elle est consacrée à tout ce qui est en rapport à la gestion et à la création des articles de votre site web. Elle comprend 3 sous-rubriques : « Ajouter », « Catégories » et « Etiquettes ».',
      },
      {
        type: 'p',
        content:
          'La première vous permettra de créer, d’ajouter, de publier et de supprimer des articles. La seconde est consacrée à la gestion des différentes catégories : ajout et suppression de catégorie et classement des articles. Enfin, la dernière vous servira à gérer les étiquettes des articles de votre site.',
      },
      {
        type: 'H3',
        content: 'La rubrique Médias',
      },
      {
        type: 'p',
        content:
          'La troisième rubrique de WordPress est celle appelée « Médias ». Comme son nom l’indique, elle sert à gérer les médias que vous aurez intégrés au contenu de votre site WordPress : images, sons et vidéos. Elle comprend deux sous-rubriques. À savoir : « Bibliothèque » et « Ajouter ».',
      },
      {
        type: 'p',
        content:
          'La sous-catégorie « Bibliothèque » vous permettra d’accéder à la liste de tous les médias que contient votre site. La bibliothèque vous servira également à gérer ces derniers.',
      },
      {
        type: 'p',
        content:
          'Pour ajouter un média à votre site WordPress, vous devrez vous rendre dans la sous-rubrique « Ajouter » de la rubrique « Médias ». Vous pourrez y affecter un média à un article ou à une page de votre choix. Chaque média ajouté via cette rubrique sera automatiquement visible depuis la bibliothèque.',
      },
      {
        type: 'H2',
        content: 'La rubrique Pages',
      },
      {
        type: 'p',
        content:
          'La troisième rubrique de WordPress est celle appelée « Médias ». Comme son nom l’indique, elle serLa rubrique suivante est celle intitulée « Pages ». Elle regroupe les options qui vous permettront de gérer les différentes pages de votre site web. Cette rubrique comprend deux options : « Toutes les pages » et « Ajouter ».',
      },
      {
        type: 'p',
        content:
          'La première vous permettra d’accéder à la liste de toutes les pages de votre site et de les gérer. L’option « Ajouter », quant à elle, vous permettra de créer et d’intégrer des pages à votre site. La création de pages donnera une meilleure structure à votre site internet et à vos menus tout en allégeant son contenu.',
      },
      {
        type: 'p',
        content:
          'Sachez également qu’une page vous permettra de mettre en avant des informations et du contenu sans tenir compte de leur chronologie ou de l’ordre alphabétique de son titre. Cela permet de garder d’actualité le contenu de la page aussi longtemps que vous le désirerez, ce qui n’est pas possible avec un simple article.t à gérer les médias que vous aurez intégrés au contenu de votre site WordPress : images, sons et vidéos. Elle comprend deux sous-rubriques. À savoir : « Bibliothèque » et « Ajouter ».',
      },
      {
        type: 'p',
        content:
          'La sous-catégorie « Bibliothèque » vous permettra d’accéder à la liste de tous les médias que contient votre site. La bibliothèque vous servira également à gérer ces derniers.',
      },
      {
        type: 'p',
        content:
          'Pour ajouter un média à votre site web WordPress, vous devrez vous rendre dans la sous-rubrique « Ajouter » de la rubrique « Médias ». Vous pourrez y affecter un média à un article ou à une page de votre choix. Chaque média ajouté via cette rubrique sera automatiquement visible depuis la bibliothèque.',
      },
      {
        type: 'H2',
        content: 'Que pouvez-vous faire sur WordPress ?',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Présentez vos activités : Présentez votre société, vos services et faites la promotion de vos produits. Utilisez des outils avancés de statistiques et d’optimisation des moteurs de recherche et rejoignez des communautés sur les réseaux sociaux pour développer votre entreprise.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Créez un blog : WordPress est le meilleur outil de blogging au monde. Très facile à prendre en main, il permet de créer un blog rapidement. Avec un blog, vous pouvez partager une de vos passions avec le reste du monde ou mettre en avant les qualités de votre entreprise.',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Concevez un Portfolio : Grâce aux milliers de thèmes, il est très facile de mettre en place un Portfolio. Avec WordPress, il sera très aisé d’effectuer la mise en page qui vous convient. Ce CMS apporte les outils permettant de réaliser un PortFolio de qualité.',
          },
        ],
      },
      {
        type: 'H2',
        content: 'La rubrique Apparence',
      },
      {
        type: 'p',
        content:
          'La troisième rubrique de WordPress est celle appelée « Médias ». Comme son nom l’indique, elle sert à gérer les médias que vous aurez intégrés au contenu de votre site WordPress : images, sons et vidéos. Elle comprend deux sous-rubriques. À savoir : « Bibliothèque » et « Ajouter ».',
      },
      {
        type: 'p',
        content:
          'La sous-catégorie « Bibliothèque » vous permettra d’accéder à la liste de tous les médias que contient votre site. La bibliothèque vous servira également à gérer ces derniers.',
      },
      {
        type: 'p',
        content:
          'Pour ajouter un média à votre site WordPress, vous devrez vous rendre dans la sous-rubrique « Ajouter » de la rubrique « Médias ». Vous pourrez y affecter un média à un article ou à une page de votre choix. Chaque média ajouté via cette rubrique sera automatiquement visible depuis la bibliothèque.',
      },
      {
        type: 'H3',
        content: 'La rubrique Pages',
      },
      {
        type: 'p',
        content:
          'La rubrique suivante est celle intitulée « Pages ». Elle regroupe les options qui vous permettront de gérer les différentes pages de votre site web. Cette rubrique comprend deux options : « Toutes les pages » et « Ajouter ».',
      },
      {
        type: 'p',
        content:
          'La première vous permettra d’accéder à la liste de toutes les pages de votre site et de les gérer. L’option « Ajouter », quant à elle, vous permettra de créer et d’intégrer des pages à votre site. La création de pages donnera une meilleure structure à votre site internet et à vos menus tout en allégeant son contenu.',
      },
      {
        type: 'p',
        content:
          'Sachez également qu’une page vous permettra de mettre en avant des informations et du contenu sans tenir compte de leur chronologie ou de l’ordre alphabétique de son titre. Cela permet de garder d’actualité le contenu de la page aussi longtemps que vous le désirerez, ce qui n’est pas possible avec un simple article.',
      },
      {
        type: 'H3',
        content: 'La rubrique Apparence',
      },
      {
        type: 'p',
        content:
          'La présentation et l’apparence d’un site web influeront grandement sur son confort d’utilisation, mais aussi sur son image auprès des visiteurs, ce qui fait de la rubrique « Apparence » est l’une des plus importantes de WordPress.',
      },
      {
        type: 'p',
        content:
          'Elle sert à personnaliser l’apparence du site ainsi que la présentation des différents menus et contenus. La rubrique « Apparence » comprend plusieurs sous-rubriques, dont : « Thème », « Personnaliser », « Widgets », « Menus » et « Editeur ».',
      },
      {
        type: 'p',
        content:
          'La rubrique « Apparence » est l’une des plus importantes. En effet, elle vous permettra de personnaliser l’apparence de votre site web : thème, menu et widgets. Pour ceux qui ont un niveau avancé en PHP et en HTML, il est même possible modifier complètement un thème pour en obtenir un qui est unique et plus complet.',
      },
      {
        type: 'p',
        content:
          'La sous-rubrique « Thème », comme son nom l’indique vous servira à changer le thème de votre site. WordPress propose notamment une large gamme de thèmes gratuits, mais il vous sera également possible de vous procurer des thèmes payants plus élaborer.',
      },
      {
        type: 'p',
        content:
          'La sous-rubrique « Personnaliser » vous servira à faire des modifications de bases sur le thème de votre site web. Comme le choix de la couleur du thème, le choix de l’arrière-plan ainsi que la gestion des différents menus et des widgets.',
      },
      {
        type: 'p',
        content:
          'La sous-catégorie « Widgets » vous permettra de vous procurer, d’intégrer des widgets à votre site web.',
      },
      {
        type: 'p',
        content:
          'Le « Menu » vous servira quant à elle à personnaliser la disposition des menus de votre site ainsi que leur structure.',
      },
      {
        type: 'p',
        content:
          'Enfin, l’éditeur vous permettra de modifier les fichiers sources de votre site. Pour cela, vous devrez toutefois avoir un niveau avancé sur la maîtrise des langages informatiques, notamment, le PHP et le HTML.',
      },
      {
        type: 'H3',
        content: 'La rubrique Commentaires',
      },
      {
        type: 'p',
        content:
          'La rubrique « Commentaires » est un espace consacré à la gestion de commentaire, ou plutôt à leur modération. Tous les commentaires laissés par vos visiteurs sur vos pages que sur vos articles y sont répertoriés. Elle vous servira à répondre, à les publier, à les mettre en attente ou à les supprimer. La rubrique « Commentaires » vous sera d’une grande utilité pour évaluer la qualité et la popularité de votre site et de son contenu.',
      },
      {
        type: 'H3',
        content: 'La rubrique Extension',
      },
      {
        type: 'p',
        content:
          'La rubrique ” Extension ” du menu principal vous permettra d’ajouter des extensions à votre site. Ces dernières permettent d’ajouter de nouvelles fonctionnalités à votre site web. Par exemple pour ajouter des boutons et pluggins spécifiques ou pour protéger l’espace commentaire de votre site des spams. Tout comme avec les thèmes, WordPress dispose d’un large catalogue d’extensions gratuites, mais vous pourrez aussi opter pour des extensions payantes.',
      },
      {
        type: 'H3',
        content: 'La rubrique Réglages',
      },
      {
        type: 'p',
        content:
          'La dernière rubrique du menu est celle nommée « Réglages ». Cette rubrique est consacrée à la personnalisation des paramètres généraux de votre site internet. Vous pourrez y modifier le nom de votre site, changer le nombre d’articles maximum de votre page d’accueil, définir le fuseau horaire, mais aussi activer ou désactiver votre espace commentaire. Cette liste de fonctionnalité n’est bien sur pas exhaustive.',
      },
      {
        type: 'H3',
        content: 'La rubrique Utilisateurs',
      },
      {
        type: 'p',
        content:
          'La gestion et la création d’un site et de son contenu peut requiert la collaboration de plusieurs personnes. C’est pour cela que la rubrique « Utilisateurs » est l’une des plus importantes du menu de WordPress. Il s’agit d’un espace dans lequel vous pourrez créer et gérer différents comptes utilisateurs de votre site. Vous pourrez non seulement leur donner accès au back-office de votre site, mais aussi paramétrer leur droit de modification et les fonctionnalités auxquelles chaque compte aura accès afin de définir précisément leur rôle.',
      },
      {
        type: 'p',
        content:
          'WordPress permet notamment de créer 5 catégories de comptes utilisateurs : administrateur, éditeur, auteur, contributeur et abonné.',
      },
      {
        type: 'p',
        content:
          'Un compte « administrateur » dispose de tous les droits en termes de paramétrage, de gestion et de modification de votre site web.',
      },
      {
        type: 'p',
        content:
          'Un compte « éditeur » quant à lui n’aura accès qu’à l’intégralité des fonctionnalités concernant la gestion des articles et pages de votre site. Il pourra notamment ajouter, modifier et supprimer des articles et des catégories, mais n’aura pas accès aux paramètres et aux différents réglages de votre site, comme la modification du thème de ce dernier par exemple.',
      },
      {
        type: 'p',
        content:
          'Un compte « auteur » est libre de créer, de modifier, de publier et de supprimer uniquement ses articles sans devoir avoir l’aval d’un compte éditeur ou d’un compte administrateur.',
      },
      {
        type: 'p',
        content:
          'Un compte « contributeur » donne uniquement le droit d’écrire des articles et de les soumettre pour publication.',
      },
      {
        type: 'p',
        content:
          'Enfin, un compte « abonné » n’a droit qu’à la lecture du contenu du site et de laisser des commentaires.',
      },
    ],
  },
  {
    id: 12,
    slug: 'netlinking-semantique',
    classname: 'netlinking-semantique',
    content: [
      {
        type: 'H1',
        content: 'Netlinking Sémantique',
      },
      {
        type: 'H2',
        content: 'AGENCE RÉFÉRENCEMENT NATUREL',
      },
      {
        type: 'H2',
        content: 'L’importance du Netlinking(E-réputation)',
      },
      {
        type: 'p',
        content:
          'Le Netlinking sémantique permet d’améliorer votre E-réputation en augmentant les backlings de qualité. Nous améliorons la visibilité des différentes pages de votre site internet avec des liens adaptés à la thématique et à votre structure. La popularité est un critère très important dans une stratégie de référencement naturel.',
      },
      {
        type: 'p',
        content:
          'Le Netlinking est l’action de développer le nombre de liens hypertextes externes vers votre site. Il permet d’augmenter la popularité de votre site et donc votre E-réputation. La popularité dans le domaine du référencement de sites internet se mesure par le nombre de liens externes de qualités, pointant vers une page web. Par conséquent, plus une page web a de liens populaires pointant vers elle, plus elle est populaire et donc meilleur sera son référencement naturel et son positionnement dans les moteurs de recherche.',
      },
      {
        type: 'p',
        content:
          'En ce qui concerne le Netlinking Sémantique, il faut créer des liens (backlink) vers votre site tou en prenant compte du vocabulaire et du champs sémantique du site qui fait le lien. Google analyse la sémantique des contenus rédactionnels pour que les backlinks aient un sens.',
      },
      {
        type: 'p',
        content:
          'Nous utilisons deux indicateurs pour juger la qualité de votre popularité : Trust flow et Citation flow.',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Trust flow : qualité des backlinks (des liens entrants) pointant vers votre site.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Citation flow : quantité de backlinks qui pointent vers votre site.',
          },
        ],
      },

      {
        type: 'p',
        content:
          'Dans cette étape, le travail sera de désavouer les liens avec de mauvaises ancres qui pénalisent le référencement et trouver de nouveaux liens à forte réputation afin d’augmenter la notoriété du site internet.',
      },
      {
        type: 'H3',
        content: 'Élaborer une stratégie de Netlinking',
      },
      {
        type: 'p',
        content:
          'Cela consiste d’être à la recherche active de liens à diffuser sur le web (annuaires, blogs, forums, sites thématiques, communiqués de presse…). Il s’agit donc de trouver des emplacements où déposer des liens pertinents, c’est-à-dire optimisés au niveau de l’ancre et du contexte thématique de la page.',
      },
      {
        type: 'H2',
        content:
          '75% des personnes ne vont jamais après la 1ère page des moteurs de recherche.',
      },
      {
        type: 'p',
        content: 'Sur un mot clé, si vous passez de la :',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content: '2ème à la 1ère place = trafic x 2',
          },
          {
            id: 2,
            type: 'li',
            content: '4ème à la 2ème place = trafic x 2',
          },
          {
            id: 3,
            type: 'li',
            content: '8ème à la 5ème place = trafic x 2',
          },
          {
            id: 4,
            type: 'li',
            content: '8ème à la 3ème place = trafic x 3',
          },
        ],
      },
      {
        type: 'p',
        content:
          'Par cosnéquence, passer de la 2ème page au milieu de la 1ère page = trafic x 10',
      },
      {
        type: 'H2',
        content: 'Principe du Netlinking Sémantique',
      },
      {
        type: 'p',
        content:
          'Pour positionner un site internet, Google commence par calculer « notoriété ». Il se base sur les liens entre les sites en mesurant trois piliers suivants :',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Plus un site reçoit des liens d’autres sites plus ces derniers auront de la puissance.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Si votre lien est proche des site à forte autorité, plus la votre sera meilleure',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Les liens que vous devez recevoir doit traiter d’un sujet connexe et être dans une thématique pertinente',
          },
        ],
      },
      {
        type: 'H2',
        content: 'Comment Google contrôle la qualité des liens ?',
      },
      {
        type: 'ul',
        content: [
          {
            id: 1,
            type: 'li',
            content:
              'Pour commencer, Google Pingouin contrôle les liens de type spam (grande quantité de liens artificiels) et la suroptimisation des ancres.',
          },
          {
            id: 2,
            type: 'li',
            content:
              'Ensuite, Google Panda contrôle les contenus de mauvaise qualité (généré automatiquement)',
          },
          {
            id: 3,
            type: 'li',
            content:
              'Pour finir, Google utilise des équipes de « Quality Rater » pour évaluer les résultats et la qualité des sites.',
          },
        ],
      },
      {
        type: 'p',
        content:
          'Si Google estime qu’un lien est « artificiel » il ne va plus pénaliser le site qui reçoit le lien mais il va juste ne pas tenir compte de ce lien. Il est donc fondamental de construire un profil de liens « naturels » en diversifiant les sources et les types de liens.',
      },
    ],
  },
];

export default landingPage;
