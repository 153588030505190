import './Header.scss';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { actionToggleIsOpen } from '../store/actionCreatore/actionCreators';
import { RootState } from '../store/type';
import iconMenu from '../../assets/icones/menu.png';
import logoDevoji from '../../assets/images/logo_devoji.png';
import MobileMenu from './MobileMenu/MobileMenu';
import DesktoplMenu from './DesktopMobile/DesktopMenu';

const Header = () => {
  const menuIsOpen = useSelector((state: RootState) => state.menu.isOpen);
  const dispatch = useDispatch();

  const handleClickOpenMenu = () => {
    dispatch(actionToggleIsOpen());
  };
  type HeaderColorMap = Record<string, string>;
  type FontColorMap = Record<string, string>;
  type ButtonColorMap = Record<string, string>;

  const headerColors: HeaderColorMap = {
    '/nos-expertises': '#f2f2f2',
    '/agence': '#112138',
    '/offre-ecommerce': '#375a8b',
    '/consulting-web': '#1D2735',
    '/contact': '#273D51',
  };

  const fontColors: FontColorMap = {
    '/nos-expertises': '#2d2d34',
    '/agence': 'white',
    '/offre-ecommerce': 'white',
    '/consulting-web': 'white',
    '/contact': 'white',
  };

  const buttonColors: ButtonColorMap = {
    '/nos-expertises': '#2f4961',
    '/agence': 'transparent',
    '/offre-ecommerce': '#375a8b',
    '/consulting-web': '#1D2735',
    '/contact': '#273D51',
  };

  const location = useLocation();
  const headerColor = headerColors[location.pathname] || '#2f4961';
  const fontColor = fontColors[location.pathname] || 'white';
  const currentButtonColor = buttonColors[location.pathname] || 'transparent';

  return (
    <header
      className="header"
      style={{ backgroundColor: headerColor, color: fontColor }}
    >
      <div className="logo-container">
        <NavLink to="/">
          <img src={logoDevoji} alt="Devoji Logo" className="logo" />
        </NavLink>
        <NavLink to="/">
          <div className="header-logo-devoji" style={{ color: fontColor }}>
            DEVOJI{' '}
            <span className="subtitle" style={{ color: fontColor }}>
              agency
            </span>
          </div>
        </NavLink>
      </div>
      <div className="menu-mobile-header">
        <button
          className="form-btn"
          type="button"
          onClick={handleClickOpenMenu}
          style={{ backgroundColor: currentButtonColor }}
        >
          <img src={iconMenu} alt="Menu Icon" className="menu-icon" />
        </button>
        <MobileMenu isOpen={menuIsOpen} />
      </div>
      <div className="menu-desktop-header">
        <DesktoplMenu />
      </div>
    </header>
  );
};

export default Header;
