import React from 'react';
import './LandingPageCMS.scss';
import {
  CmsDataItem,
  ContentItem,
  ListItem,
} from '../../Data/interface/cmsDataInterfaces';

interface LandingPageCMSProps {
  cmsData: CmsDataItem[];
  pageSlug: string;
}

const Heading: React.FC<{
  type: 'H1' | 'H2' | 'H3' | 'H4';
  content: string;
}> = ({ type, content }) => {
  const Tag = type.toLowerCase() as keyof JSX.IntrinsicElements;
  return <Tag>{content}</Tag>;
};

const Paragraph: React.FC<{ content: string }> = ({ content }) => (
  <p>{content}</p>
);

const UnorderedList: React.FC<{ items: ListItem[] }> = ({ items }) => (
  <ul>
    {items.map((item, index) => (
      <li key={item.id || index}>{item.content}</li>
    ))}
  </ul>
);

const LandingPageCMS: React.FC<LandingPageCMSProps> = ({
  cmsData,
  pageSlug,
}) => {
  const pageData = cmsData.find((item) => item.slug === pageSlug);

  if (!pageData) {
    return <div>Page non trouvée</div>;
  }

  const renderContent = (item: ContentItem, key: number | string) => {
    switch (item.type) {
      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
        return (
          <Heading
            type={item.type}
            content={item.content as string}
            key={key}
          />
        );
      case 'p':
        return <Paragraph content={item.content as string} key={key} />;
      case 'ul':
        return <UnorderedList items={item.content as ListItem[]} key={key} />;
      default:
        return null;
    }
  };

  return (
    <div className="landing-page-container">
      <div className={pageData.classname}>
        {pageData.content.map((item, index) => renderContent(item, index))}
      </div>
    </div>
  );
};

export default LandingPageCMS;
