/* eslint-disable object-shorthand */
import { configureStore } from '@reduxjs/toolkit';
import menuReducer from './reducer/menuReducer';
import cookieReducer from './reducer/cookieReducer';

const store = configureStore({
  reducer: {
    menu: menuReducer,
    cookieConsent: cookieReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
