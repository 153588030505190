import './MainSectionTop.scss';
import pcdevoji from '../../../assets/images/devoji-site-internet.png';
import ButtonHompageTop from './ButtonHompageTop/ButtonHompageTop';

const MainSectionTop = () => {
  return (
    <div className="main-container-top">
      <div className="left-panel">
        <div className="main-section-top-homepage">
          <div className="prefix-top-hompage">DEVOJI | Agence e-commerce</div>
          <h2 className="animate-text">L&apos;agence Web qui vous conseille</h2>
          <p className="p-top-homepage">
            Que vous soyez artisans, responsables commercial, chef d’entreprise,
            TPE, PME ou indépendant, une présence en ligne est devenu
            indispensable. Avoir un site internet efficace mettant en valeur vos
            services ou vos produits et amenant un gage de professionnalisme et
            de la confiance permet de vous différencier de vos concurrents. Le
            premier réflexe des Français lors d’un achat d’un produits ou d’un
            service est d’effectuer une recherche sur Internet. Aujourd’hui, si
            vos clients ne vous trouvent pas le WEB, ils trouveront peut-être
            les offres de concurrents !
          </p>
          <div className="button-top-homepage">
            <ButtonHompageTop />
          </div>
        </div>
      </div>

      <div className="right-panel">
        <img src={pcdevoji} alt="devoji site internet" className="pc-devoji" />
      </div>
    </div>
  );
};

export default MainSectionTop;
