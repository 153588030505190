import ExpertiseSection from './ExpertiseSection/ExpertiseSection';
import './HomPage.scss';
import MainSectionServices from './MainSectionServices/MainSectionServices';
import MainSectionTop from './MainSectionTop/MainSectionTop';
import PerformanceImage from './PerformanceImage/PerformanceImage';
import expertise from '../../assets/images/homepage/expertise-web.jpg';
import experience from '../../assets/images/homepage/exeprience-web.jpg';
import ecoute from '../../assets/images/homepage/agence_web_reactive.jpg';
import ExpertiseSectionSquare from './ExpertiseSectionSquare/ExpertiseSectionSquare';
import AgencyPresentation from './AgencyPresentation/AgencyPresentation';
import PartnerLogosSlider from './PartnerLogosSlider/PartnerLogosSlider';

const HomPage = () => {
  return (
    <div className="homepage">
      <MainSectionTop />
      <MainSectionServices />
      <PerformanceImage />
      <div className="hp-expertise-section">
        <div className="expertise-section-square-hp">
          <ExpertiseSectionSquare
            image={experience}
            title="Méthodologie"
            description="Notre expérience permet de cibler les atouts majeurs de votre projet et définir les objectifs prioritaires afin d’apporter des solutions à vos problématiques."
          />
        </div>
        <div className="expertise-section-hp">
          <ExpertiseSection
            image={expertise}
            title="Expertise"
            description="Plus de 15 ans d’expérience dans l’e-commerce, le développement Web, l’e-marketing, la communication digitale et entrepreneuriat."
          />
        </div>
        <div className="expertise-section-square-hp">
          <ExpertiseSectionSquare
            image={ecoute}
            title="Ecoute Réactivité"
            description="Un interlocuteur unique par projet pour assurer des échanges rapides et respecter nos engagements dans les délais. "
          />
        </div>
      </div>

      <div>
        <PartnerLogosSlider />
      </div>

      <AgencyPresentation />
    </div>
  );
};

export default HomPage;
