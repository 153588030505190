import './ RefonteSiteWeb.scss';
import site from '../../../assets/images/ecommerce/refonte/Refonte de votre site web.jpg';
import performance from '../../../assets/images/ecommerce/refonte/Amélioration des performances.jpg';
import seo from '../../../assets/images/ecommerce/refonte/Amélioration ou redéfinition de votre stratégie SEO.jpg';
import dev from '../../../assets/images/ecommerce/refonte/developpement-site-web.jpg';

const RefonteSiteWeb = () => {
  return (
    <div>
      <div className="content-section-refonte">
        <h2>Refonte de votre site web</h2>
        <div className="text-refonte">
          <div>
            <img src={site} alt="" />
          </div>
          <div>
            <h3>Modernisation de votre site</h3>
            <p>
              Donnez un nouvel élan à votre présence en ligne avec une refonte
              stratégique de votre site web. En adoptant un design moderne et
              des fonctionnalités de pointe, vous transformerez votre image
              numérique pour qu&apos;elle reflète l&apos;excellence et
              l&apos;unicité de votre marque. Cette évolution va au-delà de
              l&apos;esthétique : elle optimise l&apos;expérience utilisateur
              grâce à une ergonomie et une navigation améliorées, essentielles
              pour captiver et fidéliser vos visiteurs.
            </p>
          </div>
        </div>
      </div>
      <div className="content-section-refonte reverse-on-mobile">
        <div className="text-refonte reverse-on-mobile-p">
          <div>
            <h3>Amélioration des performances</h3>
            <p>
              Un site revu et corrigé promet également une amélioration des
              performances. Fini les temps de chargement lents qui testent la
              patience de vos utilisateurs. Nos solutions sont optimisées pour
              les Core Web Vitals, assurant ainsi une fluidité et une réactivité
              sans faille de votre plateforme.
            </p>
          </div>

          <div className="reverse-on-mobile-img">
            <img src={performance} alt="" />
          </div>
        </div>
      </div>
      <div className="content-section-refonte">
        <div className="text-refonte">
          <div>
            <img src={dev} alt="" />
          </div>
          <div>
            <h3>Optimisation pour tous les appareils</h3>{' '}
            <p>
              Votre site web n&apos;est pas encore responsive ? À l&apos;heure
              où les habitudes de vos clients évoluent, l&apos;accès à vos
              informations doit être fluide et simple, quel que soit le support
              utilisé. La refonte permet de concevoir un site web pleinement
              responsive, adapté à la navigation mobile et propice au
              e-commerce. Vous offrez ainsi une meilleure accessibilité et
              augmentez votre trafic.
            </p>
          </div>
        </div>
      </div>
      <div className="content-section-refonte reverse-on-mobile">
        <div className="text-refonte reverse-on-mobile-p">
          <div>
            <h3>Amélioration ou redéfinition de votre stratégie SEO</h3>
            <p>
              La visibilité en ligne est cruciale dans l&apos;univers digital.
              Si le référencement de votre site actuel est insuffisant, la
              refonte est l&apos;occasion idéale pour repenser votre stratégie
              SEO. Nous affinons votre contenu et sa structure technique pour
              satisfaire précisément aux exigences des moteurs de recherche, en
              mettant l&apos;accent sur une sémantique riche, une sélection
              pertinente de mots-clés et une stratégie de contenu ciblée, afin
              de propulser votre site en haut des résultats de recherche.
            </p>
          </div>
          <div className="reverse-on-mobile-img">
            <img src={seo} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefonteSiteWeb;
