import './IntroPrestation.scss';
import agence from '../../../assets/images/prestations/agence-web-refonte-dite-internet.jpg';

import AnimationMultiFormat from './Animation/AnimationMultiFormat';

const IntroPrestation = () => {
  return (
    <div className="IntroPrestation">
      <section className="section-title-intro">
        <div className="left-section-title">
          <h1 className="small-title-intro">web agency</h1>
          <h2 className="title-intro-prestation">
            Expertise et Innovation au Service de Votre Vision Web
          </h2>
          <p className="texte-into slide-text-intro-one">
            Plongez dans l&apos;ère numérique avec une approche sur-mesure !
            Chez DEVOJI, nous transformons vos idées en réalités digitales
            captivantes.
          </p>
        </div>
        <div className="right-section-title">
          <img
            src={agence}
            alt="Développement back-end PHP Symfony"
            className="img-swipper-slide"
          />
        </div>
      </section>
      <section className="section-title-mulitformat">
        <div className="section-title-mulitformat-left">
          <p className="small-title-intro">une expertise multiformat</p>
          <h2 className="title-animation-multiformat">
            Des développements sur tous les formats
          </h2>
          <div className="animation-placeholder">
            <AnimationMultiFormat />
          </div>
        </div>

        <div className="texte-into slide-text-intro-two">
          <p className="small-title-intro p-text-intro-two">Votre projet</p>
          <h3>Notre mission ?</h3>
          <p>
            Créer des applications web qui ne se contentent pas de répondre à
            vos besoins, mais qui les anticipent. En alliant expertise technique
            et compréhension approfondie de vos objectifs, nous développons des
            solutions web intuitives, performantes et engageantes.
          </p>
        </div>
      </section>
    </div>
  );
};

export default IntroPrestation;
