import './ButtonHompageTop.scss';
import { Link } from 'react-router-dom';

const ButtonHompageTop = () => {
  const handleClick = () => {
    window.location.href = '/contact';
  };

  return (
    <div className="button-homepage-css custom-color">
      <Link to="/contact" style={{ textDecoration: 'none' }}>
        <button className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text">Contactez-nous</span>
          </span>
        </button>
      </Link>
    </div>
  );
};

export default ButtonHompageTop;
