import React from 'react';
import './ExpertiseSection.scss';

interface ExpertiseSectionProps {
  image: string;
  title: string;
  description: string;
}

function ExpertiseSection({
  image,
  title,
  description,
}: ExpertiseSectionProps) {
  return (
    <div className="container-expertise">
      <img src={image} alt={title} className="image-expertise" />
      <div className="overlay-expertise">
        <div className="title-expertise">{title}</div>
        <div className="description-expertise">{description}</div>
      </div>
    </div>
  );
}

export default ExpertiseSection;
