import React from 'react';
import { Link } from 'react-router-dom';
import './WebStrategy.scss';

const WebStrategy = () => {
  return (
    <div className="web-strategy">
      <h2>Optinisez votre présence en ligne dès maintenant</h2>
      <div className="strategy-steps">
        <div className="step">
          <h3>1. OPTIMISEZ VOTRE PRÉSENCE EN LIGNE</h3>
          <p>
            Vous nous contactez, nous évaluons ensemble vos besoins numériques
            et élaborons une stratégie sur mesure pour booster votre visibilité.
          </p>
        </div>
        <div className="step">
          <h3>2. ANALYSEZ, AJUSTEZ, ACCELEREZ</h3>
          <p>
            Grâce à une analyse continue, nous ajustons nos stratégies pour
            accélérer votre croissance et vous maintenir à la pointe de votre
            secteur.
          </p>
        </div>
        <div className="step">
          <h3>3. INNOVEZ CONTINUELLEMENT</h3>
          <p>
            Ensemble, nous construisons l'histoire de votre succès en ligne à
            travers des stratégies personnalisées qui résonnent avec votre
            marque et votre public.
          </p>
        </div>
      </div>
      <Link to="/contact" style={{ textDecoration: 'none' }}>
        <button className="button">
          <span className="button_lg">
            <span className="button_sl"></span>
            <span className="button_text">Contactez-nous</span>
          </span>
        </button>
      </Link>
    </div>
  );
};

export default WebStrategy;
