import './TopEcommerce.scss';
import ecommerce from '../../../assets/images/ecommerce/ecommerce cms.jpg';

const TopEcommerce = () => {
  return (
    <div className="container-top-ecommerce">
      <div className="left-container-top-ecommerce">
        <h1>
          opter pour l&rsquo;Open Source,
          <br />
          <span> c&rsquo;est une démarche stratégique !</span>
        </h1>
        <img src={ecommerce} alt="" />
      </div>
      <div className="right-container-top-ecommerce">
        <div className="right-background-texte-ecommerce">
          <h2>CMS et Marketing Digital: L&rsquoAlliance Stratégique</h2>
          <p>
            Chez <span className="devoji-top-ecommerce">DEVOJI</span>, notre
            expertise diversifiée dans divers systèmes de gestion de contenu
            (CMS) nous positionne idéalement pour vous accompagner dans la
            réalisation intégrale de vos projets.
          </p>
          <p>
            Notre approche ne se limite pas à la simple création de sites web ;
            nous sommes des experts en marketing digital, avec une connaissance
            approfondie des stratégies les plus efficaces. Nous comprenons
            l&rsquo;importance d&rsquo;une intégration fluide entre votre CMS et
            vos outils essentiels tels que l&rsquo;ERP, les systèmes
            logistiques...
          </p>
          <p className="en-avant-top-ecommerce">
            Que ce soit pour reprendre un projet en cours, développer des
            fonctionnalités supplémentaires, ou vous guider dans un projet
            complet, notre équipe est dédiée à fournir des solutions
            personnalisées et efficaces.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopEcommerce;
